import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { Button } from "../../components/Button";
import { IconChevronRight } from "../../components/IconChevronRight";
import { IconFacebook } from "../../components/IconFacebook";
import { IconFacebookWrapper } from "../../components/IconFacebookWrapper";
import { IconInstagram } from "../../components/IconInstagram";
import { IconInstagramWrapper } from "../../components/IconInstagramWrapper";
import { IconJr } from "../../components/IconJr";
import { IconLinkedin } from "../../components/IconLinkedin";
import { IconLinkedinWrapper } from "../../components/IconLinkedinWrapper";
import { IconX } from "../../components/IconX";
import { IconXWrapper } from "../../components/IconXWrapper";
import { IconYoutube } from "../../components/IconYoutube";
import { IconYoutubeWrapper } from "../../components/IconYoutubeWrapper";
import { Menu } from "../../components/Menu";
import "./style.css";

export const HomeDesktop = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="home-desktop"
      style={{
        backgroundColor:
          screenWidth < 1440
            ? "var(--semantic-background-color-primary)"
            : undefined,
        height:
          screenWidth < 1440
            ? "5810px"
            : screenWidth >= 1440
              ? "5655px"
              : undefined,
        minWidth:
          screenWidth < 1440
            ? "393px"
            : screenWidth >= 1440
              ? "1440px"
              : undefined,
      }}
    >
      {screenWidth < 1440 && (
        <>
          <header className="header">
            <img
              className="company-logo"
              alt="Company logo"
              src="/img/company-logo-1.png"
            />

            <div className="masthead">
              <p className="p">Collective Design for Social Impact</p>
            </div>
          </header>

          <img className="line" alt="Line" src="/img/line-1.png" />

          <div className="home-page-entry">
            <p className="heading">
              Empowering Communities Through Innovative Learning Solutions
            </p>

            <div className="photo">
              <img
                className="placeholder-image"
                alt="Placeholder image"
                src="/img/placeholder-image-2.png"
              />
            </div>

            <p className="text">
              Harnessing the power of design, technology, and artificial
              intelligence to create transformative learning experiences
              grounded on communities and their voice.
            </p>

            <div className="vertical-menu">
              <div className="menu-wrapper">
                <Menu PROJECTSText="ABOUT" className="menu-instance" />
              </div>
            </div>
          </div>

          <div className="v-rtality">
            <div className="section-title">
              <div className="build-learn" >
              <video width="393px" height="330px" autoPlay loop muted playsInline backgroundColor="white">
                <source src="/img/Build-Learn-Play.mp4" type="video/mp4"/>
                </video>
                </div>

              <img
                className="a-group-of-teens"
                alt="A group of teens"
                src="/img/a-group-of-teens-having-a-good-time-with-vr-realistic-photograp.png"
              />

              <div className="content">
                <p className="heading-2">
                  Creating Engagement to Support Teen Mental Health with Virtual
                  Reality
                </p>

                <p className="text-2">
                  <span className="span">
                    JR has a proven track record of securing grants and
                    implementing impactful programs. Our initiatives, such as{" "}
                  </span>

                  <a
                    href="https://www.vrtality.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span className="text-wrapper-3">VR for mental health</span>
                  </a>

                  <span className="span">
                    , empower communities to thrive.{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="nourishing-minds">
            <img className="pot" alt="Pot" src="/img/pot.png" />

            <img
              className="NM-teens"
              alt="Nm teens"
              src="/img/nm-teens-1.png"
            />

            <div className="section-title-2">
              <div className="div-2">
                <p className="heading-3">
                  Discover the transformative process behind JR&#39;s innovative
                  learning solutions.
                </p>
              </div>

              <div className="div-3">
                <p className="text-wrapper-4">
                  At JR, we believe in a collaborative approach to learning. Our
                  process begins with understanding the unique needs of each
                  community. By integrating technology and co-creation, we
                  design tailored solutions that empower and inspire.
                </p>
              </div>
            </div>
          </div>

          <div className="sea-level">
            <img
              className="bench-separator"
              alt="Bench separator"
              src="/img/bench-separator.png"
            />

            <div className="layout">
              <div className="row">
                <div className="div-3">
                  <div className="content-2">
                    <p className="heading-4">
                      Empower Your Community Through Innovative Curriculum
                      Design and Engagement
                    </p>

                    <p className="text-3">
                      Our curriculum design focuses on inclusivity and
                      research-based strategies to enhance learning.
                    </p>
                  </div>

                  <div className="action">
                    <Button
                      alternate={false}
                      className="button-instance"
                      iconPosition="trailing"
                      override={<IconChevronRight className="instance-node" />}
                      small={false}
                      style="link"
                      text="Learn More"
                    />
                  </div>
                </div>

                <div className="list-item">
                  <div className="content-3">
                    <div className="column">
                      <img
                        className="img"
                        alt="Placeholder image"
                        src="/img/placeholder-image-3.png"
                      />

                      <div className="content-4">
                        <a
                          href="https://cig.uw.edu/projects/vr-slr-south-seattle/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <p className="heading-5">
                            Exploring Sea Level Rise in South Seattle with
                            Virtual Reality
                          </p>
                        </a>

                        <p className="text-4">
                          Our programs are designed to empower underrepresented
                          voices.
                        </p>
                      </div>
                    </div>

                    <p className="heading-6">
                      Inspiring Community Empowerment Through Collaborative
                      Strategies and Initiatives
                    </p>

                    <p className="text-3">
                      We provide tools and strategies that mobilize communities
                      to inspire change.
                    </p>
                  </div>

                  <div className="button-wrapper">
                    <Button
                      alternate={false}
                      className="button-instance"
                      iconPosition="trailing"
                      override={<IconChevronRight className="instance-node" />}
                      small={false}
                      style="link"
                      text="Join Us"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="seattle-fire">
              <img className="bench" alt="Bench" src="/img/bench.png" />

              <a
                href="https://connectedwellbeing.org/case-study-seattle-public-library/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <p className="heading-7">
                  Partnering with Leading Educational Institutions
                </p>
              </a>

              <div className="tagline-wrapper">
                <div className="text-wrapper-5">Our Projects</div>
              </div>

              <div className="layout-2" />
            </div>

            <div className="discover">
              <div className="div-2">
                <p className="heading-3">
                  Discover the Journey of JR: Innovator in Technology-Driven
                  Learning and Design
                </p>
              </div>

              <div className="div-3">
                <p className="text-wrapper-4">
                  JR&#39;s professional journey is marked by a commitment to
                  blending technology with education. As an award-winning
                  instructional designer, JR has collaborated with prestigious
                  organizations like the Tribeca Film Institute and the
                  University of Washington, creating impactful learning
                  experiences. With a focus on community empowerment, JR&#39;s
                  work has transformed the educational landscape for diverse
                  populations.
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {screenWidth >= 1440 && (
        <div className="navbar">
          <div className="container">
            <div className="column-2">
              <div className="nav-links">
                <div className="link-2">
                  <div className="link-text">About</div>
                </div>

                <div className="link-2">
                  <div className="link-text">Projects</div>
                </div>

                <div className="link-2">
                  <div className="link-text">Publications</div>
                </div>

                <div className="link-2">
                  <div className="nav-link-dropdown">
                    <div className="link-text">Resources</div>

                    <img
                      className="chevron-down"
                      alt="Chevron down"
                      src="/img/chevron-down.png"
                    />
                  </div>

                  <div className="menu-2">
                    <div className="link-text-2">Contact Us</div>

                    <div className="link-text-3">Blog Posts</div>

                    <div className="link-text-3">Testimonials</div>
                  </div>
                </div>
              </div>

              <div className="actions">
                <Button
                  alternate={false}
                  className="button-2"
                  iconPosition="no-icon"
                  small
                  style="secondary"
                  text="Join"
                />
                <Button
                  alternate={false}
                  className="style-primary-small-2"
                  divClassName="style-primary-small"
                  iconPosition="no-icon"
                  small
                  style="primary"
                  text="Sign Up"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="header-2"
        style={{
          gap:
            screenWidth >= 1440
              ? "61px"
              : screenWidth < 1440
                ? "48px"
                : undefined,
          height:
            screenWidth >= 1440
              ? "1270px"
              : screenWidth < 1440
                ? "326px"
                : undefined,
          padding:
            screenWidth >= 1440
              ? "34px 64px 112px 0px"
              : screenWidth < 1440
                ? "64px 20px"
                : undefined,
          width:
            screenWidth >= 1440
              ? "1440px"
              : screenWidth < 1440
                ? "393px"
                : undefined,
        }}
      >
        {screenWidth >= 1440 && (
          <>
            <div className="content-5">
              <img
                className="company-logo-2"
                alt="Company logo"
                src="/img/company-logo.png"
              />

              <div className="medium-length-hero-wrapper">
                <p className="medium-length-hero">
                  Empowering Communities Through Innovative Learning Solutions
                </p>
              </div>

              <div className="div-4">
                <p className="text-wrapper-6">
                  JR is an award-winning instructional designer dedicated to
                  transforming education through technology and co-creation.
                  With a mission to amplify voices and foster growth, JR&#39;s
                  innovative programs serve diverse communities and inspire
                  impactful learning experiences.
                </p>

                <div className="actions-2">
                  <Button
                    alternate={false}
                    className="button-2"
                    iconPosition="no-icon"
                    small={false}
                    style="primary"
                    text="Learn More"
                  />
                  <Button
                    alternate={false}
                    className="button-3"
                    iconPosition="no-icon"
                    small={false}
                    style="secondary"
                    text="Join Us"
                  />
                </div>
              </div>
            </div>

            <img
              className="placeholder-image-2"
              alt="Placeholder image"
              src="/img/placeholder-image.png"
            />
          </>
        )}

        {screenWidth < 1440 && (
          <div className="container-2">
            <div className="content-2">
              <div className="heading-3">Let&#39;s Collaborate for Change</div>

              <p className="text-3">
                Reach out to explore partnership opportunities today!
              </p>
            </div>

            <div className="actions-2">
              <Button
                alternate={false}
                className="button-2"
                iconPosition="no-icon"
                small={false}
                style="primary"
                text="Contact"
              />
              <Button
                alternate={false}
                className="button-3"
                iconPosition="no-icon"
                small={false}
                style="secondary"
                text="Inquire"
              />
            </div>
          </div>
        )}
      </div>

      {screenWidth >= 1440 && (
        <>
          <div className="container-wrapper">
            <div className="container-3">
              <div className="placeholder-image-3" >
              <video width="616px" height="640px" autoPlay loop muted playsInline backgroundColor="white">
                <source src="/img/Build-Learn-Play.mp4" type="video/mp4"/>
                </video></div>

              <div className="div-4">
                <div className="div-2">
                  <div className="tagline-wrapper">
                    <div className="text-wrapper-5">Tagline</div>
                  </div>

                  <div className="div-3">
                    <p className="heading-8">
                      Creating Engagement to Support Teen Mental Health
                    </p>

                    <p className="text-5">
                      <span className="text-wrapper-7">
                        JJR has a proven track record of securing grants and
                        implementing impactful programs. Our initiatives, such
                        as{" "}
                      </span>

                      <a
                        href="https://www.vrtality.org/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <span className="text-wrapper-8">
                          VR for mental health
                        </span>
                      </a>

                      <span className="text-wrapper-7">
                        , empower communities to thrive.&nbsp;&nbsp;JR
                        specializes in creating impactful learning experiences
                        that leverage technology and community collaboration.
                        With a focus on inclusivity and empowerment, JR&#39;s
                        approach transforms educational landscapes for diverse
                        audiences.
                      </span>
                    </p>
                  </div>
                </div>

                <div className="actions-3">
                  <Button
                    alternate={false}
                    className="button-2"
                    iconPosition="no-icon"
                    small={false}
                    style="secondary"
                    text="Button"
                  />
                  <Button
                    alternate={false}
                    className="button-instance"
                    iconPosition="trailing"
                    override={
                      <IconChevronRight
                        className="instance-node"
                        iconChevronRight="/img/icon-chevron-right-1.png"
                      />
                    }
                    small={false}
                    style="link"
                    text="Button"
                  />
                </div>

                <img className="frame-2" alt="Frame" src="/img/frame.png" />
              </div>
            </div>
          </div>

          <div className="div-5">
            <p className="heading-9">
              Transformative Learning Solutions Tailored for Diverse Communities
              and Needs
            </p>

            <div className="row-wrapper">
              <div className="row-2">
                <div className="div-4">
                  <div className="div-3">
                    <IconJr
                      className="icon-JR-2"
                      iconJr="/img/icon-jr-43.png"
                    />
                    <p className="heading-10">
                      Empower Your Community Through Innovative Curriculum
                      Design and Engagement
                    </p>

                    <p className="text-3">
                      Our curriculum design focuses on inclusivity and
                      research-based strategies to enhance learning.
                    </p>
                  </div>

                  <div className="action">
                    <Button
                      alternate={false}
                      className="button-instance"
                      iconPosition="trailing"
                      override={
                        <IconChevronRight
                          className="instance-node"
                          iconChevronRight="/img/icon-chevron-right.png"
                        />
                      }
                      small={false}
                      style="link"
                      text="Learn More"
                    />
                  </div>
                </div>

                <div className="div-4">
                  <div className="div-3">
                    <IconJr
                      className="icon-JR-2"
                      iconJr="/img/icon-jr-44.png"
                    />
                    <p className="heading-10">
                      Inspiring Community Empowerment Through Collaborative
                      Strategies and Initiatives
                    </p>

                    <p className="text-3">
                      We provide tools and strategies that mobilize communities
                      to inspire change.
                    </p>
                  </div>

                  <div className="action">
                    <Button
                      alternate={false}
                      className="button-instance"
                      iconPosition="trailing"
                      override={
                        <IconChevronRight
                          className="instance-node"
                          iconChevronRight="/img/icon-chevron-right-3.png"
                        />
                      }
                      small={false}
                      style="link"
                      text="Join Us"
                    />
                  </div>
                </div>

                <div className="div-4">
                  <div className="div-3">
                    <IconJr
                      className="icon-JR-2"
                      iconJr="/img/icon-jr-45.png"
                    />
                    <p className="heading-10">
                      Engaging Learning Experiences Designed to Make a Lasting
                      Impact
                    </p>

                    <p className="text-3">
                      Our learning experiences are tailored to meet the unique
                      needs of each community.
                    </p>
                  </div>

                  <div className="action">
                    <Button
                      alternate={false}
                      className="button-instance"
                      iconPosition="trailing"
                      override={
                        <IconChevronRight
                          className="instance-node"
                          iconChevronRight="/img/icon-chevron-right-4.png"
                        />
                      }
                      small={false}
                      style="link"
                      text="Explore"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="div-5">
            <div className="container-4">
              <div className="div-4">
                <div className="div-3">
                  <p className="heading-11">
                    Unlock the Power of Collaborative Learning with JR&#39;s
                    Expertise
                  </p>

                  <p className="text-5">
                    Partnering with JR means gaining access to innovative
                    educational solutions tailored to your community&#39;s
                    unique needs. Experience transformative learning that
                    empowers individuals and fosters growth.
                  </p>
                </div>

                <div className="div-2">
                  <div className="row-3">
                    <div className="div-6">
                      <div className="text-wrapper-9">Empower Communities</div>

                      <p className="text-3">
                        Leverage technology and design to amplify voices and
                        inspire change in your community.
                      </p>
                    </div>

                    <div className="div-6">
                      <div className="text-wrapper-9">Innovative Solutions</div>

                      <p className="text-3">
                        Collaborative projects that drive social impact and
                        enhance educational experiences for all.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <img
                className="placeholder-image-4"
                alt="Placeholder image"
                src="/img/placeholder-image-1.png"
              />
            </div>
          </div>

          <div className="div-5">
            <div className="div-7">
              <div className="div-6">
                <p className="heading-11">
                  Discover the transformative process behind JR&#39;s innovative
                  learning solutions.
                </p>
              </div>

              <div className="div-8">
                <p className="text-wrapper-6">
                  At JR, we believe in a collaborative approach to learning. Our
                  process begins with understanding the unique needs of each
                  community. By integrating technology and co-creation, we
                  design tailored solutions that empower and inspire.
                </p>
              </div>
            </div>

            <div className="row-wrapper">
              <div className="row-2">
                <div className="div-4">
                  <div className="div-3">
                    <IconJr
                      className="icon-JR-2"
                      iconJr="/img/icon-jr-43.png"
                    />
                    <p className="heading-10">
                      Step-by-step collaboration for impactful learning
                      experiences tailored to your needs.
                    </p>

                    <p className="text-3">
                      We work closely with you to ensure every solution
                      resonates.
                    </p>
                  </div>

                  <div className="action">
                    <Button
                      alternate={false}
                      className="button-instance"
                      iconPosition="trailing"
                      override={
                        <IconChevronRight
                          className="instance-node"
                          iconChevronRight="/img/icon-chevron-right.png"
                        />
                      }
                      small={false}
                      style="link"
                      text="Learn More"
                    />
                  </div>
                </div>

                <div className="div-4">
                  <div className="div-3">
                    <IconJr
                      className="icon-JR-2"
                      iconJr="/img/icon-jr-44.png"
                    />
                    <p className="heading-10">
                      Empowering communities through technology-driven,
                      inclusive learning experiences.
                    </p>

                    <p className="text-3">
                      Our focus is on fostering creativity and collaboration in
                      every project.
                    </p>
                  </div>

                  <div className="action">
                    <Button
                      alternate={false}
                      className="button-instance"
                      iconPosition="trailing"
                      override={
                        <IconChevronRight
                          className="instance-node"
                          iconChevronRight="/img/icon-chevron-right-3.png"
                        />
                      }
                      small={false}
                      style="link"
                      text="Sign Up"
                    />
                  </div>
                </div>

                <div className="div-4">
                  <div className="div-3">
                    <IconJr
                      className="icon-JR-2"
                      iconJr="/img/icon-jr-45.png"
                    />
                    <p className="heading-10">
                      Join us in creating a brighter future through innovative
                      education.
                    </p>

                    <p className="text-3">
                      Together, we can make a lasting impact on communities.
                    </p>
                  </div>

                  <div className="action">
                    <Button
                      alternate={false}
                      className="button-instance"
                      iconPosition="trailing"
                      override={
                        <IconChevronRight
                          className="instance-node"
                          iconChevronRight="/img/icon-chevron-right-4.png"
                        />
                      }
                      small={false}
                      style="link"
                      text="Get Started"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="div-5">
            <div className="div-9">
              <div className="div-8">
                <div className="row-4">
                  <div className="heading-11">
                    Let&#39;s Collaborate for Change
                  </div>

                  <p className="text-5">
                    Reach out to explore partnership opportunities today!
                  </p>
                </div>
              </div>

              <div className="actions-2">
                <Button
                  alternate={false}
                  className="button-2"
                  iconPosition="no-icon"
                  small={false}
                  style="primary"
                  text="Contact"
                />
                <Button
                  alternate={false}
                  className="button-3"
                  iconPosition="no-icon"
                  small={false}
                  style="secondary"
                  text="Inquire"
                />
              </div>
            </div>
          </div>

          <div className="div-5">
            <div className="div-7">
              <div className="div-6">
                <p className="heading-11">
                  Discover the Journey of JR: Innovator in Technology-Driven
                  Learning and Design
                </p>
              </div>

              <div className="div-8">
                <p className="text-wrapper-6">
                  JR&#39;s professional journey is marked by a commitment to
                  blending technology with education. As an award-winning
                  instructional designer, JR has collaborated with prestigious
                  organizations like the Tribeca Film Institute and the
                  University of Washington, creating impactful learning
                  experiences. With a focus on community empowerment, JR&#39;s
                  work has transformed the educational landscape for diverse
                  populations.
                </p>
              </div>
            </div>
          </div>

          <div className="footer">
            <div className="div-9">
              <div className="div-8">
                <img
                  className="color-dark"
                  alt="Color dark"
                  src="/img/color-dark.png"
                />
              </div>

              <div className="links">
                <div className="text-wrapper-10">About Us</div>

                <div className="text-wrapper-10">Our Services</div>

                <div className="text-wrapper-10">Contact Us</div>

                <div className="text-wrapper-10">Blog Posts</div>

                <div className="text-wrapper-10">FAQs</div>
              </div>

              <div className="social-links">
                <IconFacebookWrapper
                  className="instance-node"
                  iconFacebook="/img/icon-facebook-1.png"
                />
                <IconInstagramWrapper
                  className="instance-node"
                  iconInstagram="/img/icon-instagram-1.png"
                />
                <IconXWrapper
                  className="instance-node"
                  iconX="/img/icon-x-1.png"
                />
                <IconLinkedinWrapper
                  className="instance-node"
                  iconLinkedin="/img/icon-linkedin-1.png"
                />
                <IconYoutubeWrapper
                  className="instance-node"
                  iconYoutube="/img/icon-youtube-1.png"
                />
              </div>
            </div>

            <div className="credits">
              <div className="divider" />

              <div className="row-5">
                <p className="element-relume-all">
                  © 2024 JR. All rights reserved.
                </p>

                <div className="text-wrapper-11">Privacy Policy</div>

                <div className="text-wrapper-11">Terms of Service</div>

                <div className="text-wrapper-11">Cookie Settings</div>
              </div>
            </div>

            <p className="background-image-by">
              <span className="text-wrapper-12">background image by </span>

              <a
                href="https://unsplash.com/@gaspanik?utm_content=creditCopyText&amp;utm_medium=referral&amp;utm_source=unsplash"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-12">Masaaki Komori</span>
              </a>

              <span className="text-wrapper-12"> on </span>

              <a
                href="https://unsplash.com/photos/two-footprints-in-the-sand-of-a-beach-4XZ_CHm95eY?utm_content=creditCopyText&amp;utm_medium=referral&amp;utm_source=unsplash"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-12">Unsplash</span>
              </a>
            </p>
          </div>

          <img
            className="background"
            alt="Background"
            src="/img/background.png"
          />
        </>
      )}

      {screenWidth < 1440 && (
        <>
          <img
            className="clouds-separation"
            alt="Clouds separation"
            src="/img/clouds-separation.png"
          />

          <div className="footer-2">
            <div className="content-6">
              <div className="div-10">
                <img
                  className="color-dark-2"
                  alt="Color dark"
                  src="/img/color-dark-1.png"
                />
              </div>

              <div className="div-10">
                <div className="link-one">About Us</div>

                <div className="text-wrapper-13">Our Services</div>

                <div className="text-wrapper-13">Contact Us</div>

                <div className="text-wrapper-13">Blog Posts</div>

                <div className="text-wrapper-13">FAQs</div>
              </div>

              <div className="social-links-2">
                <IconFacebook
                  className="instance-node"
                  iconFacebook="/img/icon-facebook-3.png"
                />
                <IconInstagram
                  className="instance-node"
                  iconInstagram="/img/icon-instagram-3.png"
                />
                <IconX className="instance-node" iconX="/img/icon-x-3.png" />
                <IconLinkedin
                  className="instance-node"
                  iconLinkedin="/img/icon-linkedin-3.png"
                />
                <IconYoutube
                  className="instance-node"
                  iconYoutube="/img/icon-youtube-3.png"
                />
              </div>
            </div>

            <div className="credits-2">
              <div className="divider" />

              <div className="row-6">
                <div className="footer-links">
                  <div className="text-wrapper-11">Privacy Policy</div>

                  <div className="text-wrapper-14">Terms of Service</div>

                  <div className="text-wrapper-14">Cookie Settings</div>
                </div>

                <p className="element-relume-all-2">
                  © 2024 JR. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
