/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const SpacingVertical = ({ spacing, visibility, className }) => {
  return (
    <div
      className={`flex flex-col items-start relative ${visibility ? "[border-bottom-style:dashed]" : ""} ${
        visibility ? "border-[#009688]" : ""
      } ${
        spacing === "one" && !visibility
          ? "w-[4px]"
          : visibility && spacing === "two"
          ? "w-[10px]"
          : spacing === "two" && !visibility
          ? "w-[8px]"
          : visibility && spacing === "three"
          ? "w-[14px]"
          : spacing === "three" && !visibility
          ? "w-[12px]"
          : spacing === "four" && visibility
          ? "w-[18px]"
          : spacing === "four" && !visibility
          ? "w-[16px]"
          : visibility && spacing === "five"
          ? "w-[22px]"
          : spacing === "five" && !visibility
          ? "w-[20px]"
          : visibility && spacing === "six"
          ? "w-[26px]"
          : spacing === "six" && !visibility
          ? "w-[24px]"
          : spacing === "seven" && visibility
          ? "w-[30px]"
          : spacing === "seven" && !visibility
          ? "w-[28px]"
          : spacing === "eight" && visibility
          ? "w-[34px]"
          : spacing === "eight" && !visibility
          ? "w-[32px]"
          : "w-[6px]"
      } ${visibility ? "border-t" : ""} ${visibility ? "[border-top-style:dashed]" : ""} ${
        visibility ? "border-b" : ""
      } ${visibility ? "bg-[#0096881f]" : ""} ${className}`}
    >
      <div
        className={`w-px relative ${
          spacing === "two"
            ? "h-[16px]"
            : spacing === "three"
            ? "h-[24px]"
            : spacing === "four"
            ? "h-[32px]"
            : spacing === "five"
            ? "h-[40px]"
            : spacing === "six"
            ? "h-[48px]"
            : spacing === "seven"
            ? "h-[56px]"
            : spacing === "eight"
            ? "h-[64px]"
            : "h-[8px]"
        }`}
      />
    </div>
  );
};

SpacingVertical.propTypes = {
  spacing: PropTypes.oneOf(["seven", "two", "three", "four", "one", "five", "eight", "six"]),
  visibility: PropTypes.bool,
};
