/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ProgressCircular11 = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="18"
      viewBox="0 0 17 18"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2C8.91925 2 9.82951 2.18106 10.6788 2.53284C11.5281 2.88463 12.2997 3.40024 12.9497 4.05025C13.5998 4.70026 14.1154 5.47194 14.4672 6.32122C14.8189 7.1705 15 8.08075 15 9C15 9.91925 14.8189 10.8295 14.4672 11.6788C14.1154 12.5281 13.5998 13.2997 12.9497 13.9497C12.2997 14.5998 11.5281 15.1154 10.6788 15.4672C9.8295 15.8189 8.91925 16 8 16C7.08074 16 6.17049 15.8189 5.32121 15.4672C4.47193 15.1154 3.70026 14.5998 3.05025 13.9497C2.40024 13.2997 1.88462 12.5281 1.53284 11.6788C1.18106 10.8295 1 9.91925 1 9C1 8.08074 1.18106 7.17049 1.53284 6.32121C1.88463 5.47193 2.40024 4.70026 3.05026 4.05025C3.70027 3.40024 4.47194 2.88462 5.32122 2.53284C6.1705 2.18106 7.08075 2 8 2L8 2Z"
        opacity="0"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M8.00012 1C9.05069 1 10.091 1.20693 11.0616 1.60896C12.0322 2.011 12.9141 2.60028 13.657 3.34315C14.3998 4.08602 14.9891 4.96793 15.3912 5.93853C15.7932 6.90914 16.0001 7.94943 16.0001 9C16.0001 10.0506 15.7932 11.0909 15.3912 12.0615C14.9891 13.0321 14.3998 13.914 13.657 14.6569C12.9141 15.3997 12.0322 15.989 11.0616 16.391C10.091 16.7931 9.05069 17 8.00012 17C6.94954 17 5.90925 16.7931 4.93865 16.391C3.96804 15.989 3.08613 15.3997 2.34326 14.6569"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeOpacity="0.38"
        strokeWidth="2"
      />
    </svg>
  );
};
