/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconInstagram = ({
  className,
  iconInstagram = "/img/icon-instagram.png",
}) => {
  return (
    <img
      className={`icon-instagram ${className}`}
      alt="Icon instagram"
      src={iconInstagram}
    />
  );
};

IconInstagram.propTypes = {
  iconInstagram: PropTypes.string,
};
