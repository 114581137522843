/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Projects } from "../Projects";
import "./style.css";

export const Menu = ({ className, PROJECTSText = "ABUTT" }) => {
  return (
    <div className={`menu ${className}`}>
      <div className="frame">
        <Projects
          className="projects"
          divClassName="PROJECTS-instance"
          property1="default"
          text={PROJECTSText}
        />
      </div>

      <div className="publications-wrapper">
        <Projects
          className="publications"
          divClassName="design-component-instance-node"
          property1="default"
          text="PROJECTS"
        />
      </div>

      <div className="contact-wrapper">
        <Projects
          className="contact"
          divClassName="contact-2"
          property1="default"
          text="PUBLICATIONS"
        />
      </div>
    </div>
  );
};

Menu.propTypes = {
  PROJECTSText: PropTypes.string,
};
