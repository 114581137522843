/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Starsharp102 } from "../../icons/Starsharp102";
import { Icon } from "../Icon";

export const IconButton = ({
  size,
  color,
  stateProp,
  className,
  iconSize = "medium",
  iconIcon = <Starsharp102 className="!relative !w-[24px] !h-[24px]" color="white" />,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    size: size || "medium",
    color: color || "default",
    state: stateProp || "enabled",
  });

  return (
    <div
      className={`inline-flex flex-col items-center overflow-hidden rounded-[100px] justify-center relative ${
        state.size === "small"
          ? "p-[5px]"
          : state.size === "medium" ||
            (state.color === "inherit-white" && state.size === "large" && state.state === "focused")
          ? "p-[8px]"
          : "p-[12px]"
      } ${
        state.state === "hovered" && state.color === "primary"
          ? "bg-palette-primary-hover"
          : state.state === "hovered" && ["default", "inherit"].includes(state.color)
          ? "bg-palette-action-hover"
          : state.state === "hovered" && state.color === "inherit-white"
          ? "bg-palette-common-white-hover"
          : state.color === "primary" && state.state === "focused"
          ? "bg-palette-primary-focusvisible"
          : state.state === "focused" && ["default", "inherit"].includes(state.color)
          ? "bg-palette-action-focus"
          : state.color === "inherit-white" && state.state === "focused"
          ? "bg-palette-common-white-focus"
          : state.state === "hovered" && state.color === "secondary"
          ? "bg-palette-secondary-hover"
          : state.color === "secondary" && state.state === "focused"
          ? "bg-palette-secondary-focusvisible"
          : state.state === "hovered" && state.color === "error"
          ? "bg-palette-error-hover"
          : state.color === "error" && state.state === "focused"
          ? "bg-palette-error-focusvisible"
          : state.state === "hovered" && state.color === "warning"
          ? "bg-palette-warning-hover"
          : state.color === "warning" && state.state === "focused"
          ? "bg-palette-warning-focusvisible"
          : state.state === "hovered" && state.color === "info"
          ? "bg-palette-info-hover"
          : state.color === "info" && state.state === "focused"
          ? "bg-palette-info-focusvisible"
          : state.state === "hovered" && state.color === "success"
          ? "bg-palette-success-hover"
          : state.color === "success" && state.state === "focused"
          ? "bg-palette-success-focusvisible"
          : ""
      } ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <Icon className="!flex-[0_0_auto]" icon={iconIcon} size={iconSize} />
    </div>
  );
};

function reducer(state, action) {
  if (state.color === "primary" && state.size === "small" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "primary",
          size: "small",
          state: "hovered",
        };
    }
  }

  if (state.color === "primary" && state.size === "small" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "primary",
          size: "small",
          state: "enabled",
        };
    }
  }

  if (state.color === "inherit" && state.size === "small" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "inherit",
          size: "small",
          state: "hovered",
        };
    }
  }

  if (state.color === "inherit" && state.size === "small" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "inherit",
          size: "small",
          state: "enabled",
        };
    }
  }

  if (state.color === "inherit-white" && state.size === "small" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "inherit-white",
          size: "small",
          state: "hovered",
        };
    }
  }

  if (state.color === "inherit-white" && state.size === "small" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "inherit-white",
          size: "small",
          state: "enabled",
        };
    }
  }

  if (state.color === "default" && state.size === "small" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "default",
          size: "small",
          state: "hovered",
        };
    }
  }

  if (state.color === "default" && state.size === "small" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "default",
          size: "small",
          state: "enabled",
        };
    }
  }

  if (state.color === "secondary" && state.size === "small" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "secondary",
          size: "small",
          state: "hovered",
        };
    }
  }

  if (state.color === "secondary" && state.size === "small" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "secondary",
          size: "small",
          state: "enabled",
        };
    }
  }

  if (state.color === "error" && state.size === "small" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "error",
          size: "small",
          state: "hovered",
        };
    }
  }

  if (state.color === "error" && state.size === "small" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "error",
          size: "small",
          state: "enabled",
        };
    }
  }

  if (state.color === "warning" && state.size === "small" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "warning",
          size: "small",
          state: "hovered",
        };
    }
  }

  if (state.color === "warning" && state.size === "small" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "warning",
          size: "small",
          state: "enabled",
        };
    }
  }

  if (state.color === "info" && state.size === "small" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "info",
          size: "small",
          state: "hovered",
        };
    }
  }

  if (state.color === "info" && state.size === "small" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "info",
          size: "small",
          state: "enabled",
        };
    }
  }

  if (state.color === "success" && state.size === "small" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "success",
          size: "small",
          state: "hovered",
        };
    }
  }

  if (state.color === "success" && state.size === "small" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "success",
          size: "small",
          state: "enabled",
        };
    }
  }

  if (state.color === "primary" && state.size === "medium" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "primary",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "primary" && state.size === "medium" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "primary",
          size: "large",
          state: "enabled",
        };
    }
  }

  if (state.color === "primary" && state.size === "large" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "primary",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "inherit" && state.size === "medium" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "inherit",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "inherit" && state.size === "medium" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "inherit",
          size: "large",
          state: "enabled",
        };
    }
  }

  if (state.color === "inherit" && state.size === "large" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "inherit",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "inherit-white" && state.size === "medium" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "inherit-white",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "inherit-white" && state.size === "medium" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "inherit-white",
          size: "large",
          state: "enabled",
        };
    }
  }

  if (state.color === "inherit-white" && state.size === "large" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "inherit-white",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "default" && state.size === "medium" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "default",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "default" && state.size === "medium" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "default",
          size: "large",
          state: "enabled",
        };
    }
  }

  if (state.color === "default" && state.size === "large" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "default",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "secondary" && state.size === "medium" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "secondary",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "secondary" && state.size === "medium" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "secondary",
          size: "large",
          state: "enabled",
        };
    }
  }

  if (state.color === "secondary" && state.size === "large" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "secondary",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "error" && state.size === "medium" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "error",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "error" && state.size === "medium" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "error",
          size: "large",
          state: "enabled",
        };
    }
  }

  if (state.color === "error" && state.size === "large" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "error",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "warning" && state.size === "medium" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "warning",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "warning" && state.size === "medium" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "warning",
          size: "large",
          state: "enabled",
        };
    }
  }

  if (state.color === "warning" && state.size === "large" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "warning",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "info" && state.size === "medium" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "info",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "info" && state.size === "medium" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "info",
          size: "large",
          state: "enabled",
        };
    }
  }

  if (state.color === "info" && state.size === "large" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "info",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "success" && state.size === "medium" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "success",
          size: "medium",
          state: "hovered",
        };
    }
  }

  if (state.color === "success" && state.size === "medium" && state.state === "hovered") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "success",
          size: "large",
          state: "enabled",
        };
    }
  }

  if (state.color === "success" && state.size === "large" && state.state === "enabled") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "success",
          size: "medium",
          state: "hovered",
        };
    }
  }

  return state;
}

IconButton.propTypes = {
  size: PropTypes.oneOf(["large", "medium", "small"]),
  color: PropTypes.oneOf([
    "warning",
    "inherit",
    "info",
    "default",
    "success",
    "secondary",
    "primary",
    "inherit-white",
    "error",
  ]),
  stateProp: PropTypes.oneOf(["hovered", "disabled", "focused", "enabled"]),
  iconSize: PropTypes.string,
};
