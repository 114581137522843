/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { LibraryInstance } from "../LibraryInstance";

export const Stack = ({
  instances,
  direction,
  spacing,
  className,
  override = <LibraryInstance className="!mr-[-1.00px] !mt-[-1.00px] !ml-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]" />,
}) => {
  return (
    <div
      className={`inline-flex items-start relative ${
        (direction === "column" && instances === "five") ||
        (direction === "column" && instances === "four") ||
        (direction === "column" && instances === "three") ||
        (direction === "column" && instances === "two")
          ? "flex-wrap"
          : ""
      } ${direction === "row" || (direction === "column" && instances === "one") ? "flex-col" : ""} ${
        (instances === "five" && spacing === "one") ||
        (instances === "four" && spacing === "one") ||
        (instances === "three" && spacing === "one") ||
        (instances === "two" && spacing === "one")
          ? "gap-[var(--sizing-1)]"
          : spacing === "two"
          ? "gap-[var(--sizing-2)]"
          : spacing === "three"
          ? "gap-[var(--sizing-3)]"
          : spacing === "four"
          ? "gap-[var(--sizing-4)]"
          : spacing === "five-1"
          ? "gap-[var(--sizing-5)]"
          : spacing === "six"
          ? "gap-[var(--sizing-6)]"
          : spacing === "seven"
          ? "gap-[var(--sizing-7)]"
          : spacing === "eight"
          ? "gap-[var(--sizing-8)]"
          : (direction === "column" && instances === "five" && spacing === "zero") ||
            (direction === "column" && instances === "four" && spacing === "zero") ||
            (direction === "column" && instances === "three" && spacing === "zero") ||
            (direction === "column" && instances === "two" && spacing === "zero")
          ? "gap-[0px_0px]"
          : instances === "one" && spacing === "one"
          ? "gap-[8px]"
          : ""
      } ${className}`}
    >
      {["five", "four", "three", "two"].includes(instances) && (
        <LibraryInstance
          className={
            direction === "column"
              ? "!mt-[-1.00px] !ml-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
              : "!mr-[-1.00px] !mt-[-1.00px] !ml-[-1.00px] !flex-[0_0_auto]"
          }
        />
      )}

      {["five", "four", "three", "two"].includes(instances) && (
        <LibraryInstance
          className={
            instances === "two" && direction === "row"
              ? "!mr-[-1.00px] !ml-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
              : direction === "column" && instances === "two"
              ? "!mr-[-1.00px] !mt-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
              : direction === "column" && ["five", "four", "three"].includes(instances)
              ? "!mt-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
              : "!mr-[-1.00px] !ml-[-1.00px] !flex-[0_0_auto]"
          }
        />
      )}

      {instances === "one" && <>{override}</>}

      {["five", "four", "three"].includes(instances) && (
        <LibraryInstance
          className={
            direction === "column" && ["five", "four"].includes(instances)
              ? "!mt-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
              : instances === "three" && direction === "row"
              ? "!mr-[-1.00px] !ml-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
              : instances === "three" && direction === "column"
              ? "!mr-[-1.00px] !mt-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
              : "!mr-[-1.00px] !ml-[-1.00px] !flex-[0_0_auto]"
          }
        />
      )}

      {["five", "four"].includes(instances) && (
        <LibraryInstance
          className={
            instances === "four" && direction === "column"
              ? "!mr-[-1.00px] !mt-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
              : direction === "row" && instances === "five"
              ? "!mr-[-1.00px] !ml-[-1.00px] !flex-[0_0_auto]"
              : direction === "column" && instances === "five"
              ? "!mt-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
              : "!mr-[-1.00px] !ml-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
          }
        />
      )}

      {instances === "five" && (
        <LibraryInstance
          className={
            direction === "column"
              ? "!mr-[-1.00px] !mt-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
              : "!mr-[-1.00px] !ml-[-1.00px] !mb-[-1.00px] !flex-[0_0_auto]"
          }
        />
      )}
    </div>
  );
};

Stack.propTypes = {
  instances: PropTypes.oneOf(["two", "three", "four", "one", "five"]),
  direction: PropTypes.oneOf(["row", "column"]),
  spacing: PropTypes.oneOf(["seven", "zero", "five-1", "two", "three", "four", "one", "eight", "six"]),
};
