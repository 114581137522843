/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { CheckedFalseWrapper } from "../CheckedFalseWrapper";
import { ColorErrorStateWrapper } from "../ColorErrorStateWrapper";

export const LabelPlacementEndWrapper = ({
  labelPlacement,
  disabled,
  className,
  colorErrorStateWrapperValue = "Label",
  colorErrorStateWrapperDivClassName,
}) => {
  return (
    <div
      className={`inline-flex items-center relative ${
        ["bottom", "top"].includes(labelPlacement) ? "flex-col" : ""
      } ${className}`}
    >
      {["start", "top"].includes(labelPlacement) && (
        <ColorErrorStateWrapper
          className="!flex-[0_0_auto]"
          color="one"
          state={disabled ? "disabled" : "enabled"}
          value="Label"
        />
      )}

      <CheckedFalseWrapper
        checked={false}
        className="!flex-[0_0_auto]"
        color={disabled ? "default" : "primary"}
        indeterminate={false}
        size="medium"
        stateProp={disabled ? "disabled" : "enabled"}
      />
      {["bottom", "end"].includes(labelPlacement) && (
        <ColorErrorStateWrapper
          className="!flex-[0_0_auto]"
          color="one"
          divClassName={colorErrorStateWrapperDivClassName}
          state={disabled ? "disabled" : "enabled"}
          value={colorErrorStateWrapperValue}
        />
      )}
    </div>
  );
};

LabelPlacementEndWrapper.propTypes = {
  labelPlacement: PropTypes.oneOf(["start", "top", "end", "bottom"]),
  disabled: PropTypes.bool,
  colorErrorStateWrapperValue: PropTypes.string,
};
