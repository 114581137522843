import React from "react";
import { Link } from "react-router-dom";
import { Homefilled1 } from "../../icons/Homefilled1";
import { Peoplefilled1 } from "../../icons/Peoplefilled1";
import { Searchfilled1 } from "../../icons/Searchfilled1";
import { IconOnlyFalseWrapper } from "../IconOnlyFalseWrapper";

export const BottomNavigationWrapper = ({
  className,
  iconOnlyFalseWrapperBottomNavigationIcon = (
    <Link to="/">
      <Homefilled1 className="!relative !w-[24px] !h-[24px] !object-cover" />
    </Link>
  ),
  override = (
    <Link to="/menu">
      <Peoplefilled1 className="!relative !w-[24px] !h-[24px] !object-cover" color="#173F56" />
    </Link>
  ),
  iconOnlyFalseWrapperBottomNavigationIcon1 = (
    <Link to="/search">
      <Searchfilled1 className="!relative !w-[24px] !h-[24px] !object-cover" color="#173F56" />
    </Link>
  ),
  iconOnlyFalseWrapperIconOnlyFalseClassName,
}) => {
  return (
    <div className={`inline-flex items-start relative ${className}`}>
      <IconOnlyFalseWrapper
        bottomNavigationActiveFalseLabelClassName="!h-[56px] !mt-[-1.00px] !mb-[-1.00px] !flex-1 !flex !grow"
        bottomNavigationActiveTrueLabelClassName="!h-[56px] !mt-[-1.00px] !mb-[-1.00px] !flex-1 !flex !grow"
        bottomNavigationActiveTrueLabelClassNameOverride="!h-[56px] !mt-[-1.00px] !mb-[-1.00px] !flex-1 !flex !grow"
        bottomNavigationIcon={iconOnlyFalseWrapperBottomNavigationIcon}
        bottomNavigationIcon1={override}
        bottomNavigationLabelContent="home"
        bottomNavigationLabelContent1="browse"
        bottomNavigationLabelContent2="join"
        className={iconOnlyFalseWrapperIconOnlyFalseClassName}
        iconOnly={false}
        items="three"
        override={iconOnlyFalseWrapperBottomNavigationIcon1}
      />
    </div>
  );
};