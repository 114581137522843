/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LibraryInstance = ({ className }) => {
  return (
    <div
      className={`inline-flex flex-col items-center justify-center px-[42px] py-[4px] relative bg-[#9747ff0a] rounded-[var(--shape-borderradius)] border border-dashed border-[#9747ff] ${className}`}
    >
      <div className="relative w-fit mt-[-0.50px] font-input-helper font-[number:var(--input-helper-font-weight)] text-[#9747ff] text-[length:var(--input-helper-font-size)] text-center tracking-[var(--input-helper-letter-spacing)] leading-[var(--input-helper-line-height)] whitespace-nowrap [font-style:var(--input-helper-font-style)]">
        Instance Slot
      </div>
    </div>
  );
};
