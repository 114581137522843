/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { ProgressCircular2 } from "../../icons/ProgressCircular2";

export const Button = ({
  loadingEnd = false,
  loadingStart = true,
  label = "Label",
  startIcon = false,
  endIcon = false,
  size,
  color,
  stateProp,
  variant,
  className,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    size: size || "large",
    color: color || "primary",
    state: stateProp || "enabled",
    variant: variant || "contained",
  });

  return (
    <button
      className={`all-[unset] box-border inline-flex flex-col items-center overflow-hidden rounded-[var(--shape-borderradius)] justify-center relative ${
        state.variant === "outlined"
          ? "border border-solid"
          : (state.size === "large" && state.state === "disabled" && state.variant === "text") ||
            (state.size === "large" && state.state === "loading" && state.variant === "text") ||
            (state.size === "medium" && state.state === "disabled" && state.variant === "text") ||
            (state.size === "medium" && state.state === "loading" && state.variant === "text")
          ? "border-0 border-none"
          : ""
      } ${
        (state.color === "primary" && state.state === "enabled" && state.variant === "outlined") ||
        (state.color === "primary" && state.state === "focused" && state.variant === "outlined") ||
        (state.color === "primary" && state.state === "hovered" && state.variant === "outlined")
          ? "border-palette-primary-outlinedborder"
          : (state.color === "inherit" && state.state === "enabled" && state.variant === "outlined") ||
            (state.color === "inherit" && state.state === "focused" && state.variant === "outlined") ||
            (state.color === "inherit" && state.state === "hovered" && state.variant === "outlined")
          ? "border-palette-text-primary"
          : (state.color === "inherit-white" && state.state === "enabled" && state.variant === "outlined") ||
            (state.color === "inherit-white" && state.state === "focused" && state.variant === "outlined") ||
            (state.color === "inherit-white" && state.state === "hovered" && state.variant === "outlined")
          ? "border-palette-common-white-main"
          : (state.color === "secondary" && state.state === "enabled" && state.variant === "outlined") ||
            (state.color === "secondary" && state.state === "focused" && state.variant === "outlined") ||
            (state.color === "secondary" && state.state === "hovered" && state.variant === "outlined")
          ? "border-palette-secondary-outlinedborder"
          : (state.color === "error" && state.state === "enabled" && state.variant === "outlined") ||
            (state.color === "error" && state.state === "focused" && state.variant === "outlined") ||
            (state.color === "error" && state.state === "hovered" && state.variant === "outlined")
          ? "border-palette-error-outlinedborder"
          : (state.color === "warning" && state.state === "enabled" && state.variant === "outlined") ||
            (state.color === "warning" && state.state === "focused" && state.variant === "outlined") ||
            (state.color === "warning" && state.state === "hovered" && state.variant === "outlined")
          ? "border-palette-warning-outlinedborder"
          : (state.color === "info" && state.state === "enabled" && state.variant === "outlined") ||
            (state.color === "info" && state.state === "focused" && state.variant === "outlined") ||
            (state.color === "info" && state.state === "hovered" && state.variant === "outlined")
          ? "border-palette-info-outlinedborder"
          : (state.color === "success" && state.state === "enabled" && state.variant === "outlined") ||
            (state.color === "success" && state.state === "focused" && state.variant === "outlined") ||
            (state.color === "success" && state.state === "hovered" && state.variant === "outlined")
          ? "border-palette-success-outlinedborder"
          : (state.size === "large" && state.state === "disabled" && state.variant === "text") ||
            (state.size === "large" && state.state === "loading" && state.variant === "text") ||
            (state.size === "medium" && state.state === "disabled" && state.variant === "text") ||
            (state.size === "medium" && state.state === "loading" && state.variant === "text") ||
            (state.state === "disabled" && state.variant === "outlined") ||
            (state.state === "loading" && state.variant === "outlined")
          ? "border-palette-action-disabledbackground"
          : ""
      } ${
        state.variant === "contained" && state.state === "enabled"
          ? "shadow-elevation-2"
          : state.variant === "contained" && state.state === "focused"
          ? "shadow-elevation-6"
          : state.state === "hovered" && state.variant === "contained"
          ? "shadow-elevation-4"
          : ""
      } ${
        state.size === "medium" && ["contained", "outlined"].includes(state.variant)
          ? "px-[16px] py-[6px]"
          : state.size === "small" && ["contained", "outlined"].includes(state.variant)
          ? "px-[10px] py-[4px]"
          : state.variant === "text" && state.size === "medium"
          ? "px-[8px] py-[6px]"
          : state.variant === "text" && state.size === "large"
          ? "px-[11px] py-[8px]"
          : state.size === "small" && state.variant === "text"
          ? "px-[5px] py-[4px]"
          : "px-[22px] py-[8px]"
      } ${
        state.color === "primary" && state.variant === "contained" && ["enabled", "focused"].includes(state.state)
          ? "bg-palette-primary-main"
          : (state.color === "inherit-white" && state.state === "enabled" && state.variant === "contained") ||
            (state.color === "inherit-white" && state.state === "focused" && state.variant === "contained") ||
            (state.color === "inherit" && state.state === "enabled" && state.variant === "contained") ||
            (state.color === "inherit" && state.state === "focused" && state.variant === "contained")
          ? "bg-[color:var(--material-colors-grey-300)]"
          : state.color === "secondary" && state.variant === "contained" && ["enabled", "focused"].includes(state.state)
          ? "bg-palette-secondary-main"
          : state.color === "error" && state.variant === "contained" && ["enabled", "focused"].includes(state.state)
          ? "bg-palette-error-main"
          : state.color === "warning" && state.variant === "contained" && ["enabled", "focused"].includes(state.state)
          ? "bg-palette-warning-main"
          : state.color === "info" && state.variant === "contained" && ["enabled", "focused"].includes(state.state)
          ? "bg-palette-info-main"
          : state.color === "success" && state.variant === "contained" && ["enabled", "focused"].includes(state.state)
          ? "bg-palette-success-main"
          : state.state === "hovered" && state.variant === "contained" && state.color === "primary"
          ? "bg-palette-primary-dark"
          : state.state === "hovered" &&
            state.variant === "contained" &&
            ["inherit-white", "inherit"].includes(state.color)
          ? "bg-material-colors-grey-100"
          : state.state === "hovered" && state.variant === "contained" && state.color === "secondary"
          ? "bg-palette-secondary-dark"
          : state.state === "hovered" && state.variant === "contained" && ["error", "warning"].includes(state.color)
          ? "bg-palette-error-dark"
          : state.state === "hovered" && state.color === "info" && state.variant === "contained"
          ? "bg-palette-info-dark"
          : state.state === "hovered" && state.variant === "contained" && state.color === "success"
          ? "bg-palette-success-dark"
          : state.variant === "contained" && ["disabled", "loading"].includes(state.state)
          ? "bg-palette-action-disabledbackground"
          : state.color === "primary" && state.state === "hovered" && ["outlined", "text"].includes(state.variant)
          ? "bg-palette-primary-hover"
          : (state.color === "inherit-white" && state.state === "hovered" && state.variant === "outlined") ||
            (state.color === "inherit-white" && state.state === "hovered" && state.variant === "text") ||
            (state.color === "inherit" && state.state === "hovered" && state.variant === "outlined") ||
            (state.color === "inherit" && state.state === "hovered" && state.variant === "text")
          ? "bg-palette-action-hover"
          : state.color === "secondary" && state.state === "hovered" && ["outlined", "text"].includes(state.variant)
          ? "bg-palette-secondary-hover"
          : state.color === "error" && state.state === "hovered" && ["outlined", "text"].includes(state.variant)
          ? "bg-palette-error-hover"
          : state.color === "warning" && state.state === "hovered" && ["outlined", "text"].includes(state.variant)
          ? "bg-palette-warning-hover"
          : state.color === "info" && state.state === "hovered" && ["outlined", "text"].includes(state.variant)
          ? "bg-palette-info-hover"
          : state.color === "success" && state.state === "hovered" && ["outlined", "text"].includes(state.variant)
          ? "bg-palette-success-hover"
          : ""
      } ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      {["disabled", "enabled", "hovered", "loading"].includes(state.state) && (
        <div
          className={`inline-flex items-center gap-[8px] flex-[0_0_auto] justify-center relative ${
            state.state === "loading" && state.size === "medium"
              ? "px-0 py-[4px]"
              : state.state === "loading" && state.size === "small"
              ? "px-0 py-[3px]"
              : state.size === "large" && state.state === "loading" && ["outlined", "text"].includes(state.variant)
              ? "px-0 py-[5px]"
              : ""
          }`}
        >
          {["disabled", "enabled", "hovered"].includes(state.state) && (
            <button
              className={`all-[unset] box-border w-fit mt-[-1.00px] whitespace-nowrap relative ${
                state.size === "medium"
                  ? "font-button-medium"
                  : state.size === "small"
                  ? "font-button-small"
                  : "font-button-large"
              } ${
                state.size === "medium"
                  ? "tracking-[var(--button-medium-letter-spacing)]"
                  : state.size === "small"
                  ? "tracking-[var(--button-small-letter-spacing)]"
                  : "tracking-[var(--button-large-letter-spacing)]"
              } ${
                state.size === "medium"
                  ? "text-[length:var(--button-medium-font-size)]"
                  : state.size === "small"
                  ? "text-[length:var(--button-small-font-size)]"
                  : "text-[length:var(--button-large-font-size)]"
              } ${
                state.size === "medium"
                  ? "[font-style:var(--button-medium-font-style)]"
                  : state.size === "small"
                  ? "[font-style:var(--button-small-font-style)]"
                  : "[font-style:var(--button-large-font-style)]"
              } ${
                state.color === "primary" &&
                state.variant === "contained" &&
                ["enabled", "hovered"].includes(state.state)
                  ? "text-palette-primary-contrast"
                  : state.color === "inherit" && ["enabled", "hovered"].includes(state.state)
                  ? "text-palette-text-primary"
                  : state.color === "inherit-white" && ["enabled", "hovered"].includes(state.state)
                  ? "text-palette-common-white-main"
                  : state.color === "secondary" &&
                    state.variant === "contained" &&
                    ["enabled", "hovered"].includes(state.state)
                  ? "text-palette-secondary-contrast"
                  : state.color === "error" &&
                    state.variant === "contained" &&
                    ["enabled", "hovered"].includes(state.state)
                  ? "text-palette-error-contrast"
                  : state.color === "warning" &&
                    state.variant === "contained" &&
                    ["enabled", "hovered"].includes(state.state)
                  ? "text-palette-warning-contrast"
                  : state.color === "info" &&
                    state.variant === "contained" &&
                    ["enabled", "hovered"].includes(state.state)
                  ? "text-palette-info-contrast"
                  : state.color === "success" &&
                    state.variant === "contained" &&
                    ["enabled", "hovered"].includes(state.state)
                  ? "text-palette-success-contrast"
                  : state.state === "disabled"
                  ? "text-palette-action-disabled"
                  : (state.color === "secondary" && state.state === "enabled" && state.variant === "outlined") ||
                    (state.color === "secondary" && state.state === "enabled" && state.variant === "text") ||
                    (state.color === "secondary" && state.state === "hovered" && state.variant === "outlined") ||
                    (state.color === "secondary" && state.state === "hovered" && state.variant === "text")
                  ? "text-palette-secondary-main"
                  : (state.color === "error" && state.state === "enabled" && state.variant === "outlined") ||
                    (state.color === "error" && state.state === "enabled" && state.variant === "text") ||
                    (state.color === "error" && state.state === "hovered" && state.variant === "outlined") ||
                    (state.color === "error" && state.state === "hovered" && state.variant === "text")
                  ? "text-palette-error-main"
                  : (state.color === "warning" && state.state === "enabled" && state.variant === "outlined") ||
                    (state.color === "warning" && state.state === "enabled" && state.variant === "text") ||
                    (state.color === "warning" && state.state === "hovered" && state.variant === "outlined") ||
                    (state.color === "warning" && state.state === "hovered" && state.variant === "text")
                  ? "text-palette-warning-main"
                  : (state.color === "info" && state.state === "enabled" && state.variant === "outlined") ||
                    (state.color === "info" && state.state === "enabled" && state.variant === "text") ||
                    (state.color === "info" && state.state === "hovered" && state.variant === "outlined") ||
                    (state.color === "info" && state.state === "hovered" && state.variant === "text")
                  ? "text-palette-info-main"
                  : (state.color === "success" && state.state === "enabled" && state.variant === "outlined") ||
                    (state.color === "success" && state.state === "enabled" && state.variant === "text") ||
                    (state.color === "success" && state.state === "hovered" && state.variant === "outlined") ||
                    (state.color === "success" && state.state === "hovered" && state.variant === "text")
                  ? "text-palette-success-main"
                  : "text-palette-primary-main"
              } ${
                state.size === "medium"
                  ? "font-[number:var(--button-medium-font-weight)]"
                  : state.size === "small"
                  ? "font-[number:var(--button-small-font-weight)]"
                  : "font-[number:var(--button-large-font-weight)]"
              } ${
                state.size === "medium"
                  ? "leading-[var(--button-medium-line-height)]"
                  : state.size === "small"
                  ? "leading-[var(--button-small-line-height)]"
                  : "leading-[var(--button-large-line-height)]"
              }`}
            >
              {label}
            </button>
          )}

          {state.state === "loading" && (
            <>
              <>
                {loadingStart && (
                  <ProgressCircular2
                    className={
                      state.variant === "contained" && state.size === "large"
                        ? "!relative !flex-[0_0_auto] !mr-[-1.00px]"
                        : "!relative !flex-[0_0_auto] !mt-[-1.00px] !mb-[-1.00px] !mr-[-1.00px]"
                    }
                  />
                )}
              </>
            </>
          )}
        </div>
      )}

      {state.state === "focused" && (
        <>
          <img
            className={`left-0 top-0 absolute ${
              state.size === "medium" && ["contained", "outlined"].includes(state.variant)
                ? "w-[75px]"
                : state.size === "small" && ["contained", "outlined"].includes(state.variant)
                ? "w-[61px]"
                : state.variant === "text" && state.size === "medium"
                ? "w-[59px]"
                : state.variant === "text" && state.size === "large"
                ? "w-[69px]"
                : state.size === "small" && state.variant === "text"
                ? "w-[51px]"
                : "w-[91px]"
            } ${state.size === "medium" ? "h-[36px]" : state.size === "small" ? "h-[30px]" : "h-[42px]"}`}
            alt="Focus ripple"
            src={
              state.color === "inherit" && state.variant === "contained" && state.size === "large"
                ? "/img/focusripple-1.svg"
                : state.color === "inherit-white" && state.variant === "contained" && state.size === "large"
                ? "/img/focusripple-2.svg"
                : state.variant === "contained" && state.color === "secondary" && state.size === "large"
                ? "/img/focusripple-3.svg"
                : state.variant === "contained" && state.color === "error" && state.size === "large"
                ? "/img/focusripple-4.svg"
                : state.color === "warning" && state.variant === "contained" && state.size === "large"
                ? "/img/focusripple-5.svg"
                : state.color === "info" && state.variant === "contained" && state.size === "large"
                ? "/img/focusripple-6.svg"
                : state.variant === "contained" && state.color === "success" && state.size === "large"
                ? "/img/focusripple-7.svg"
                : state.color === "primary" && state.variant === "contained" && state.size === "medium"
                ? "/img/focusripple-8.svg"
                : state.color === "inherit" && state.variant === "contained" && state.size === "medium"
                ? "/img/focusripple-9.svg"
                : state.color === "inherit-white" && state.variant === "contained" && state.size === "medium"
                ? "/img/focusripple-10.svg"
                : state.color === "secondary" && state.variant === "contained" && state.size === "medium"
                ? "/img/focusripple-11.svg"
                : state.variant === "contained" && state.color === "error" && state.size === "medium"
                ? "/img/focusripple-12.svg"
                : state.color === "warning" && state.variant === "contained" && state.size === "medium"
                ? "/img/focusripple-13.svg"
                : state.color === "info" && state.variant === "contained" && state.size === "medium"
                ? "/img/focusripple-14.svg"
                : state.variant === "contained" && state.color === "success" && state.size === "medium"
                ? "/img/focusripple-15.svg"
                : state.variant === "contained" && state.size === "small" && state.color === "primary"
                ? "/img/focusripple-16.svg"
                : state.color === "inherit" && state.size === "small" && state.variant === "contained"
                ? "/img/focusripple-17.svg"
                : state.size === "small" && state.variant === "contained" && state.color === "inherit-white"
                ? "/img/focusripple-18.svg"
                : state.variant === "contained" && state.size === "small" && state.color === "secondary"
                ? "/img/focusripple-19.svg"
                : state.variant === "contained" && state.size === "small" && state.color === "error"
                ? "/img/focusripple-20.svg"
                : state.size === "small" && state.color === "warning" && state.variant === "contained"
                ? "/img/focusripple-21.svg"
                : state.color === "info" && state.variant === "contained" && state.size === "small"
                ? "/img/focusripple-22.svg"
                : state.variant === "contained" && state.size === "small" && state.color === "success"
                ? "/img/focusripple-23.svg"
                : state.variant === "outlined" && state.color === "primary" && state.size === "large"
                ? "/img/focusripple-24.svg"
                : state.color === "inherit" && state.variant === "outlined" && state.size === "large"
                ? "/img/focusripple-25.svg"
                : state.color === "inherit-white" && state.variant === "outlined" && state.size === "large"
                ? "/img/focusripple-26.svg"
                : state.variant === "outlined" && state.color === "secondary" && state.size === "large"
                ? "/img/focusripple-27.svg"
                : state.variant === "outlined" && state.color === "error" && state.size === "large"
                ? "/img/focusripple-28.svg"
                : state.color === "warning" && state.variant === "outlined" && state.size === "large"
                ? "/img/focusripple-29.svg"
                : state.color === "info" && state.variant === "outlined" && state.size === "large"
                ? "/img/focusripple-30.svg"
                : state.variant === "outlined" && state.color === "success" && state.size === "large"
                ? "/img/focusripple-31.svg"
                : state.color === "primary" && state.variant === "outlined" && state.size === "medium"
                ? "/img/focusripple-32.svg"
                : state.color === "inherit" && state.variant === "outlined" && state.size === "medium"
                ? "/img/focusripple-33.svg"
                : state.color === "inherit-white" && state.variant === "outlined" && state.size === "medium"
                ? "/img/focusripple-34.svg"
                : state.color === "secondary" && state.variant === "outlined" && state.size === "medium"
                ? "/img/focusripple-35.svg"
                : state.variant === "outlined" && state.color === "error" && state.size === "medium"
                ? "/img/focusripple-36.svg"
                : state.color === "warning" && state.variant === "outlined" && state.size === "medium"
                ? "/img/focusripple-37.svg"
                : state.variant === "outlined" && state.color === "info" && state.size === "medium"
                ? "/img/focusripple-38.svg"
                : state.variant === "outlined" && state.color === "success" && state.size === "medium"
                ? "/img/focusripple-39.svg"
                : state.variant === "outlined" && state.size === "small" && state.color === "primary"
                ? "/img/focusripple-40.svg"
                : state.color === "inherit" && state.variant === "outlined" && state.size === "small"
                ? "/img/focusripple-41.svg"
                : state.color === "inherit-white" && state.variant === "outlined" && state.size === "small"
                ? "/img/focusripple-42.svg"
                : state.variant === "outlined" && state.size === "small" && state.color === "secondary"
                ? "/img/focusripple-43.svg"
                : state.variant === "outlined" && state.size === "small" && state.color === "error"
                ? "/img/focusripple-44.svg"
                : state.color === "warning" && state.variant === "outlined" && state.size === "small"
                ? "/img/focusripple-45.svg"
                : state.variant === "outlined" && state.color === "info" && state.size === "small"
                ? "/img/focusripple-46.svg"
                : state.variant === "outlined" && state.size === "small" && state.color === "success"
                ? "/img/focusripple-47.svg"
                : state.color === "primary" && state.variant === "text" && state.size === "medium"
                ? "/img/focusripple-48.svg"
                : state.color === "inherit" && state.variant === "text" && state.size === "medium"
                ? "/img/focusripple-49.svg"
                : state.color === "inherit-white" && state.variant === "text" && state.size === "medium"
                ? "/img/focusripple-50.svg"
                : state.color === "secondary" && state.variant === "text" && state.size === "medium"
                ? "/img/focusripple-51.svg"
                : state.variant === "text" && state.color === "error" && state.size === "medium"
                ? "/img/focusripple-52.svg"
                : state.color === "warning" && state.variant === "text" && state.size === "medium"
                ? "/img/focusripple-53.svg"
                : state.color === "info" && state.variant === "text" && state.size === "medium"
                ? "/img/focusripple-54.svg"
                : state.variant === "text" && state.color === "success" && state.size === "medium"
                ? "/img/focusripple-55.svg"
                : state.variant === "text" && state.color === "primary" && state.size === "large"
                ? "/img/focusripple-56.svg"
                : state.color === "inherit" && state.variant === "text" && state.size === "large"
                ? "/img/focusripple-57.svg"
                : state.color === "inherit-white" && state.variant === "text" && state.size === "large"
                ? "/img/focusripple-58.svg"
                : state.variant === "text" && state.color === "secondary" && state.size === "large"
                ? "/img/focusripple-59.svg"
                : state.variant === "text" && state.color === "error" && state.size === "large"
                ? "/img/focusripple-60.svg"
                : state.color === "warning" && state.variant === "text" && state.size === "large"
                ? "/img/focusripple-61.svg"
                : state.color === "info" && state.variant === "text" && state.size === "large"
                ? "/img/focusripple-62.svg"
                : state.variant === "text" && state.color === "success" && state.size === "large"
                ? "/img/focusripple-63.svg"
                : state.size === "small" && state.variant === "text" && state.color === "primary"
                ? "/img/focusripple-64.svg"
                : state.color === "inherit" && state.size === "small" && state.variant === "text"
                ? "/img/focusripple-65.svg"
                : state.color === "inherit-white" && state.size === "small" && state.variant === "text"
                ? "/img/focusripple-66.svg"
                : state.size === "small" && state.variant === "text" && state.color === "secondary"
                ? "/img/focusripple-67.svg"
                : state.size === "small" && state.variant === "text" && state.color === "error"
                ? "/img/focusripple-68.svg"
                : state.color === "warning" && state.size === "small" && state.variant === "text"
                ? "/img/focusripple-69.svg"
                : state.color === "info" && state.size === "small" && state.variant === "text"
                ? "/img/focusripple-70.svg"
                : state.size === "small" && state.variant === "text" && state.color === "success"
                ? "/img/focusripple-71.svg"
                : "/img/focusripple.svg"
            }
          />
          <div className="inline-flex items-center gap-[8px] flex-[0_0_auto] justify-center relative">
            <button
              className={`all-[unset] box-border w-fit mt-[-1.00px] whitespace-nowrap relative ${
                state.size === "medium"
                  ? "font-button-medium"
                  : state.size === "small"
                  ? "font-button-small"
                  : "font-button-large"
              } ${
                state.size === "medium"
                  ? "tracking-[var(--button-medium-letter-spacing)]"
                  : state.size === "small"
                  ? "tracking-[var(--button-small-letter-spacing)]"
                  : "tracking-[var(--button-large-letter-spacing)]"
              } ${
                state.size === "medium"
                  ? "text-[length:var(--button-medium-font-size)]"
                  : state.size === "small"
                  ? "text-[length:var(--button-small-font-size)]"
                  : "text-[length:var(--button-large-font-size)]"
              } ${
                state.size === "medium"
                  ? "[font-style:var(--button-medium-font-style)]"
                  : state.size === "small"
                  ? "[font-style:var(--button-small-font-style)]"
                  : "[font-style:var(--button-large-font-style)]"
              } ${
                state.variant === "contained" && state.color === "primary"
                  ? "text-palette-primary-contrast"
                  : state.color === "inherit"
                  ? "text-palette-text-primary"
                  : state.color === "inherit-white"
                  ? "text-palette-common-white-main"
                  : state.variant === "contained" && state.color === "secondary"
                  ? "text-palette-secondary-contrast"
                  : state.variant === "contained" && state.color === "error"
                  ? "text-palette-error-contrast"
                  : state.color === "warning" && state.variant === "contained"
                  ? "text-palette-warning-contrast"
                  : state.color === "info" && state.variant === "contained"
                  ? "text-palette-info-contrast"
                  : state.variant === "contained" && state.color === "success"
                  ? "text-palette-success-contrast"
                  : state.color === "secondary" && ["outlined", "text"].includes(state.variant)
                  ? "text-palette-secondary-main"
                  : state.color === "error" && ["outlined", "text"].includes(state.variant)
                  ? "text-palette-error-main"
                  : state.color === "warning" && ["outlined", "text"].includes(state.variant)
                  ? "text-palette-warning-main"
                  : state.color === "info" && ["outlined", "text"].includes(state.variant)
                  ? "text-palette-info-main"
                  : state.color === "success" && ["outlined", "text"].includes(state.variant)
                  ? "text-palette-success-main"
                  : "text-palette-primary-main"
              } ${
                state.size === "medium"
                  ? "font-[number:var(--button-medium-font-weight)]"
                  : state.size === "small"
                  ? "font-[number:var(--button-small-font-weight)]"
                  : "font-[number:var(--button-large-font-weight)]"
              } ${
                state.size === "medium"
                  ? "leading-[var(--button-medium-line-height)]"
                  : state.size === "small"
                  ? "leading-[var(--button-small-line-height)]"
                  : "leading-[var(--button-large-line-height)]"
              }`}
            >
              {label}
            </button>
          </div>
        </>
      )}
    </button>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hovered",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "enabled",
      };
  }

  return state;
}

Button.propTypes = {
  loadingEnd: PropTypes.bool,
  loadingStart: PropTypes.bool,
  label: PropTypes.string,
  startIcon: PropTypes.bool,
  endIcon: PropTypes.bool,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  color: PropTypes.oneOf(["warning", "inherit", "info", "success", "secondary", "primary", "inherit-white", "error"]),
  stateProp: PropTypes.oneOf(["enabled", "focused", "hovered", "loading", "disabled"]),
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
};
