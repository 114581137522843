/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Menufilled } from "../../icons/Menufilled";
import { Starsharp102 } from "../../icons/Starsharp102";
import { IconButton } from "../IconButton";
import { Toolbar } from "../Toolbar";

export const AppBar = ({
  color,
  className,
  toolbarVisible,
  toolbarIconButtonIconSize = "medium",
  toolbarIconButtonSize = "medium",
  toolbarMinHeightClassName,
  toolbarIconButtonIconIcon = <Menufilled className="!relative !w-[24px] !h-[24px]" color="white" />,
  toolbarHasMinHeight,
}) => {
  return (
    <div className={`w-[600px] flex flex-col items-start relative ${className}`}>
      <div
        className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] shadow-elevation-4 relative ${
          color === "secondary"
            ? "bg-palette-secondary-main"
            : color === "default"
            ? "bg-palette-components-appbar-defaultfill"
            : color === "inherit-white"
            ? "bg-[color:var(--palette-background-paper-elevation-4)]"
            : color === "transparent"
            ? "bg-[#ffffff00]"
            : "bg-palette-primary-main"
        }`}
      >
        <Toolbar
          className="!self-stretch !flex-[0_0_auto] !w-full"
          hasMinHeight={toolbarHasMinHeight}
          iconButtonColor={["default", "inherit-white", "transparent"].includes(color) ? "inherit" : undefined}
          iconButtonIconIcon={toolbarIconButtonIconIcon}
          iconButtonIconSize={toolbarIconButtonIconSize}
          iconButtonSize={toolbarIconButtonSize}
          minHeightClassName={toolbarMinHeightClassName}
          smallScreen={false}
          stack={
            <IconButton
              className="!flex-[0_0_auto]"
              color={
                ["primary", "secondary"].includes(color)
                  ? "inherit-white"
                  : ["default", "inherit-white", "transparent"].includes(color)
                  ? "inherit"
                  : undefined
              }
              iconIcon={
                <Starsharp102
                  className="!relative !w-[24px] !h-[24px]"
                  color={
                    ["primary", "secondary"].includes(color)
                      ? "white"
                      : ["default", "inherit-white", "transparent"].includes(color)
                      ? "black"
                      : undefined
                  }
                  opacity={["default", "inherit-white", "transparent"].includes(color) ? "0.87" : undefined}
                />
              }
              iconSize="medium"
              size="medium"
              stateProp="enabled"
            />
          }
          typographyBodyClassName={
            ["default", "inherit-white", "transparent"].includes(color)
              ? "!tracking-[var(--typography-h6-letter-spacing)] !text-[length:var(--typography-h6-font-size)] ![font-style:var(--typography-h6-font-style)] !font-[number:var(--typography-h6-font-weight)] !font-typography-h6 !leading-[var(--typography-h6-line-height)]"
              : undefined
          }
          variant="regular"
          visible={toolbarVisible}
        />
      </div>
    </div>
  );
};

AppBar.propTypes = {
  color: PropTypes.oneOf(["default", "secondary", "primary", "inherit-white", "transparent"]),
  toolbarVisible: PropTypes.bool,
  toolbarIconButtonIconSize: PropTypes.string,
  toolbarIconButtonSize: PropTypes.string,
  toolbarHasMinHeight: PropTypes.bool,
};
