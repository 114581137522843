/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { ArrowRedo } from "../ArrowRedo";

export const ButtonSubscribe = ({
  property1,
  className,
  overlapGroupClassName,
  rectangleClassName,
  arrowRedoArrowRedoClassName,
  divClassName,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className={`w-[268px] h-[44px] ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
    >
      <div className={`h-[43px] relative ${overlapGroupClassName}`}>
        <div
          className={`w-[225px] left-[27px] top-0 h-[30px] rounded-[25px] absolute ${
            ["default", "pressed"].includes(state.property1) ? "border border-solid" : ""
          } ${
            state.property1 === "default"
              ? "border-black"
              : state.property1 === "pressed"
              ? "border-default-stroke"
              : ""
          } ${
            state.property1 === "hover"
              ? "bg-[#d3c1a599]"
              : state.property1 === "pressed"
              ? "bg-buttons"
              : "bg-[#d3c1a5]"
          } ${rectangleClassName}`}
        />
        <ArrowRedo className={arrowRedoArrowRedoClassName} />
        <div
          className={`[font-family:'Rounded_Mplus_1c_Bold-Bold',Helvetica] w-[268px] left-0 tracking-[0] text-[13px] top-[5px] absolute font-bold text-center leading-[normal] ${
            state.property1 === "pressed" ? "text-neutral-100" : "text-[#574a3e]"
          } ${divClassName}`}
        >
          Join Our Community
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }

  return state;
}

ButtonSubscribe.propTypes = {
  property1: PropTypes.oneOf(["pressed", "hover", "default"]),
};
