/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Menufilled } from "../../icons/Menufilled";
import { Starsharp102 } from "../../icons/Starsharp102";
import { IconButton } from "../IconButton";
import { Stack } from "../Stack";
import { Typography } from "../Typography";

export const Toolbar = ({
  menu = true,
  variant,
  smallScreen,
  className,
  iconButtonSize = "medium",
  iconButtonIconSize = "medium",
  iconButtonIconIcon = <Menufilled className="!relative !w-[24px] !h-[24px]" color="white" />,
  visible = true,
  minHeightClassName,
  hasMinHeight = true,
  stack = (
    <IconButton
      className="!flex-[0_0_auto]"
      color="inherit-white"
      iconIcon={<Starsharp102 className="!relative !w-[24px] !h-[24px]" color="white" />}
      iconSize="medium"
      size="medium"
      stateProp="enabled"
    />
  ),
  iconButtonColor = "inherit-white",
  typographyBodyClassName,
}) => {
  return (
    <div
      className={`w-[600px] flex items-center relative ${
        smallScreen
          ? "pr-[var(--sizing-2)] pl-[var(--sizing-2)] py-0"
          : "pr-[var(--sizing-3)] pl-[var(--sizing-3)] py-0"
      } ${className}`}
    >
      <div className={`flex items-center grow flex-1 relative ${smallScreen ? "gap-[24px]" : "gap-[16px]"}`}>
        {menu && (
          <IconButton
            className="!flex-[0_0_auto]"
            color={iconButtonColor}
            iconIcon={iconButtonIconIcon}
            iconSize={iconButtonIconSize}
            size={iconButtonSize}
            stateProp="enabled"
          />
        )}

        {visible && (
          <Typography
            bodyClassName={typographyBodyClassName}
            className="!flex-[0_0_auto]"
            content="Website"
            gutterBottom={false}
            variant="body-1"
          />
        )}
      </div>
      {variant === "regular" && !smallScreen && <div className={`relative w-px h-[64px] ${minHeightClassName}`} />}

      {(smallScreen || variant === "dense") && (
        <Stack
          className="!flex-[0_0_auto]"
          direction="row"
          instances="one"
          override={
            <IconButton
              className="!flex-[0_0_auto]"
              color="inherit-white"
              iconIcon={<Starsharp102 className="!relative !w-[24px] !h-[24px]" color="white" />}
              iconSize="medium"
              size="medium"
              stateProp="enabled"
            />
          }
          spacing="zero"
        />
      )}

      {hasMinHeight && (
        <>
          <>
            {variant === "regular" && !smallScreen && (
              <Stack className="!flex-[0_0_auto]" direction="row" instances="one" override={stack} spacing="zero" />
            )}

            {(smallScreen || variant === "dense") && (
              <div className={`w-px mr-[-1.00px] relative ${variant === "regular" ? "h-[56px]" : "h-[48px]"}`} />
            )}
          </>
        </>
      )}
    </div>
  );
};

Toolbar.propTypes = {
  menu: PropTypes.bool,
  variant: PropTypes.oneOf(["regular", "dense"]),
  smallScreen: PropTypes.bool,
  iconButtonSize: PropTypes.string,
  iconButtonIconSize: PropTypes.string,
  visible: PropTypes.bool,
  hasMinHeight: PropTypes.bool,
  iconButtonColor: PropTypes.string,
};
