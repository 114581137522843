import React from "react";
import { Link } from 'react-router-dom';
import { AppBar } from "../../components/AppBar";
import { BottomNavigationWrapper } from "../../components/BottomNavigationWrapper";
import { CustomFormsEmail } from "../../components/CustomFormsEmail";
import { PropertyDefaultWrapper } from "../../components/PropertyDefaultWrapper";
import { Homefilled2 } from "../../icons/Homefilled2";
import { Logo1 } from "../../icons/Logo1";
import { Menufilled } from "../../icons/Menufilled";
import { Peoplefilled1 } from "../../icons/Peoplefilled1";
import { Searchfilled1 } from "../../icons/Searchfilled1";

export const Mobile = () => {
  return (
    <div className="bg-white flex flex-row justify-center w-full">
      <div className="bg-white overflow-hidden w-[393px] h-[3440px] relative" data-palette-mode="light">
        <div className="absolute w-[393px] h-[1630px] top-[1810px] left-0">
          <CustomFormsEmail
            className="!h-[260px] !absolute !left-[27px] !w-[352px] !top-[1295px]"
            formcontrollabelFormLabelDivClassName="!tracking-[var(--typography-body2-letter-spacing)] !text-[length:var(--typography-body2-font-size)] ![font-style:var(--typography-body2-font-style)] !font-[number:var(--typography-body2-font-weight)] !font-typography-body2 !leading-[var(--typography-body2-line-height)]"
          />
          <img
            className="absolute w-[393px] h-[614px] top-[1016px] left-0 object-cover"
            alt="Blue brush"
            src="/img/blue-brush-1.png"
          />
          <img
            className="absolute w-[393px] h-[561px] top-[472px] left-0 object-cover"
            alt="Brush brown"
            src="/img/brush-brown-2.png"
          />
          <p className="absolute w-[330px] top-[1072px] left-[35px] font-typography-h3 font-[number:var(--typography-h3-font-weight)] text-black text-[length:var(--typography-h3-font-size)] tracking-[var(--typography-h3-letter-spacing)] leading-[var(--typography-h3-line-height)] [font-style:var(--typography-h3-font-style)]">
            Find the art work you always wanted
          </p>
          <p className="absolute w-[306px] top-[651px] left-[43px] font-typography-body1 font-[number:var(--typography-body1-font-weight)] text-black text-[length:var(--typography-body1-font-size)] tracking-[var(--typography-body1-letter-spacing)] leading-[var(--typography-body1-line-height)] [font-style:var(--typography-body1-font-style)]">
            Here, every piece tells a story, every artist shares a vision, and every member finds a place. Join us in
            weaving a collective tapestry of creativity, where art isn&#39;t just seen—it&#39;s experienced, shared, and
            celebrated. Welcome to ArtLinks, where your journey through art begins
          </p>
          <p className="w-[320px] top-[586px] left-[14px] absolute font-typography-caption font-[number:var(--typography-caption-font-weight)] text-black text-[length:var(--typography-caption-font-size)] tracking-[var(--typography-caption-letter-spacing)] leading-[var(--typography-caption-line-height)] [font-style:var(--typography-caption-font-style)]">
            Juan Rubio, San Sebastian Couple, digital photograph
          </p>
          <img className="absolute w-[393px] h-[572px] top-0 left-0 object-cover" alt="Couple" src="/img/couple.png" />
        </div>
        <p className="absolute w-[306px] top-[1453px] left-[43px] font-typography-body1 font-[number:var(--typography-body1-font-weight)] text-black text-[length:var(--typography-body1-font-size)] tracking-[var(--typography-body1-letter-spacing)] leading-[var(--typography-body1-line-height)] [font-style:var(--typography-body1-font-style)]">
          Arts LINK is your gateway to a vibrant world where art meets community. Our platform is dedicated to bridging
          the gap between talented artists and passionate art lovers, creating a dynamic space for discovery,
          inspiration, and connection.
        </p>
        <p className="w-[425px] top-[1375px] left-[11px] absolute font-typography-caption font-[number:var(--typography-caption-font-weight)] text-black text-[length:var(--typography-caption-font-size)] tracking-[var(--typography-caption-letter-spacing)] leading-[var(--typography-caption-line-height)] [font-style:var(--typography-caption-font-style)]">
          Juan Rubio, NY City Girl, digital photograph
        </p>
        <img
          className="absolute w-[393px] h-[487px] top-[867px] left-0 object-cover"
          alt="Ny city girl"
          src="/img/ny-city-girl.png"
        />
        <div className="flex flex-col w-[392px] items-center gap-[10px] p-[10px] absolute top-[789px] left-0">
          <BottomNavigationWrapper
            className="!mr-[-23.50px] !ml-[-23.50px] !flex-[0_0_auto] !flex !bg-white !w-[419px]"
            iconOnlyFalseWrapperBottomNavigationIcon={
    
              <Homefilled2 className="!relative !w-[24px] !h-[24px] !object-cover" />
            
            }
            iconOnlyFalseWrapperBottomNavigationIcon1={
              
              <Searchfilled1 className="!relative !w-[24px] !h-[24px] !object-cover" color="#173F56" />
              
            }
            iconOnlyFalseWrapperIconOnlyFalseClassName="!h-[54px] !mr-[-10.00px] !shadow-elevation-1 !w-[429px]"
            override={<Peoplefilled1 className="!relative !w-[24px] !h-[24px] !object-cover" color="#173F56" />}
          />
        </div>
        <div className="absolute w-[392px] h-[461px] top-[281px] left-px" />
        <PropertyDefaultWrapper
          bannerAnimationBannerAnimationClassName="!mr-[-8.00px] !flex-[0_0_auto]"
          bannerAnimationDivClassName="!mt-[-29.00px] !text-palette-info-dark !tracking-[var(--list-subheader-letter-spacing)] !text-[length:var(--list-subheader-font-size)] !mb-[-27.00px] ![font-style:var(--list-subheader-font-style)] !font-[number:var(--list-subheader-font-weight)] !font-list-subheader !leading-[var(--list-subheader-line-height)]"
          className="!h-[56px] !flex !absolute !left-[53px] !w-[282px] !top-[188px]"
          property1="default"
        />
        <Logo1 className="!absolute !w-[78px] !h-[94px] !top-[94px] !left-[153px]" />
        <div
          className="!absolute !top-[225px] !border-2 !border-dashed !border-white">
        <video src="/img/Composition 1.mp4" autoPlay loop  /></div>
        <AppBar
          className="!absolute !left-0 !w-[393px] !top-0"
          color="primary"
          toolbarHasMinHeight={false}
          toolbarIconButtonIconIcon={<Menufilled className="!relative !w-[35px] !h-[35px]" color="#FDAC9C" />}
          toolbarIconButtonIconSize="large"
          toolbarIconButtonSize="large"
          toolbarMinHeightClassName="!mr-[-1.00px]"
          toolbarVisible={false}
        />
      </div>
    </div>
  );
};
