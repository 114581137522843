/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const ColorErrorStateWrapper = ({ value = "Label", color, state, className, divClassName }) => {
  return (
    <div className={`inline-flex flex-col items-start relative ${className}`}>
      <div
        className={`[font-family:'Roboto',Helvetica] w-fit mt-[-1.00px] tracking-[0.15px] text-[16px] relative font-normal whitespace-nowrap leading-[24px] ${
          color === "error" || state === "error"
            ? "text-palette-error-main-duplicate"
            : color === "warning"
            ? "text-palette-warning-main-duplicate"
            : color === "info"
            ? "text-palette-info-main-duplicate"
            : color === "success"
            ? "text-palette-success-main-duplicate"
            : color === "secondary"
            ? "text-palette-secondary-main-duplicate"
            : color === "primary"
            ? "text-palette-primary-main-duplicate"
            : state === "disabled"
            ? "text-palette-text-disabled-duplicate"
            : color === "one" && state === "enabled"
            ? "text-palette-text-primary-duplicate"
            : ""
        } ${divClassName}`}
      >
        {value}
      </div>
    </div>
  );
};

ColorErrorStateWrapper.propTypes = {
  value: PropTypes.string,
  color: PropTypes.oneOf(["warning", "info", "success", "secondary", "primary", "one", "error"]),
  state: PropTypes.oneOf(["disabled", "error", "enabled"]),
};
