import PropTypes from "prop-types";
import React from "react";
import { Favoritefilled18 } from "../../icons/Favoritefilled18";
import { Favoritefilled8 } from "../../icons/Favoritefilled8";
import { BottomNavigation } from "../BottomNavigation";

export const IconOnlyFalseWrapper = ({
  iconOnly,
  items,
  className,
  bottomNavigationLabelContent = "Label",
  bottomNavigationIcon = (
    <Favoritefilled18 className="!relative !w-[24px] !h-[24px] !object-cover" color="red" opacity="0.6" />
  ),
  bottomNavigationActiveTrueLabelClassName,
  bottomNavigationLabelContent1 = "Label",
  override = <Favoritefilled8 className="!relative !w-[24px] !h-[24px] !object-cover" color="black" opacity="0.6" />,
  bottomNavigationActiveTrueLabelClassNameOverride,
  bottomNavigationLabelContent2 = "Label",
  bottomNavigationIcon1 = (
    <Favoritefilled18 className="!relative !w-[24px] !h-[24px] !object-cover" color="black" opacity="0.38" />
  ),
  bottomNavigationActiveFalseLabelClassName,
}) => {
  return (
    <div className={`w-[500px] flex items-center bg-palette-background-paper-elevation-0 relative ${className}`}>
      {items === "three" && (
        <>
          <BottomNavigation
            active={iconOnly ? true : undefined}
            className={bottomNavigationActiveTrueLabelClassName}
            icon={bottomNavigationIcon}
            label={iconOnly ? false : true}
            labelContent={bottomNavigationLabelContent}
            override={
              iconOnly ? <Favoritefilled18 className="!relative !w-[24px] !h-[24px]" color="#173F56" /> : undefined
            }
            state="enabled"
            to="/mobile"
          />
          <BottomNavigation
            active={false}
            className={bottomNavigationActiveTrueLabelClassNameOverride}
            icon={override}
            label={iconOnly ? false : true}
            labelContent={bottomNavigationLabelContent1}
            override={
              iconOnly ? (
                <Favoritefilled8 className="!relative !w-[24px] !h-[24px]" color="black" opacity="0.6" />
              ) : undefined
            }
            state="enabled"
            to="/search"
          />
          <BottomNavigation
            active={false}
            className={bottomNavigationActiveFalseLabelClassName}
            icon={bottomNavigationIcon1}
            label={iconOnly ? false : true}
            labelContent={bottomNavigationLabelContent2}
            override={
              iconOnly ? (
                <Favoritefilled18 className="!relative !w-[24px] !h-[24px]" color="black" opacity="0.38" />
              ) : undefined
            }
            state="disabled"
            to="/menu"
          />
        </>
      )}

      {items === "four" && (
        <>
          <BottomNavigation
            active
            className="!h-[56px] !flex-1 !flex !grow"
            icon={
              !iconOnly ? (
                <Favoritefilled18 className="!relative !w-[24px] !h-[24px] !object-cover" color="#173F56" />
              ) : undefined
            }
            label={iconOnly ? false : true}
            labelContent={!iconOnly ? "Label" : undefined}
            override={
              iconOnly ? <Favoritefilled18 className="!relative !w-[24px] !h-[24px]" color="#173F56" /> : undefined
            }
            state="enabled"
            to="/mobile"
          />
          <BottomNavigation
            active={false}
            className="!h-[56px] !flex-1 !flex !grow"
            icon={
              !iconOnly ? (
                <Favoritefilled18 className="!relative !w-[24px] !h-[24px] !object-cover" color="black" opacity="0.6" />
              ) : undefined
            }
            label={iconOnly ? false : true}
            labelContent={!iconOnly ? "Label" : undefined}
            override={
              iconOnly ? (
                <Favoritefilled18 className="!relative !w-[24px] !h-[24px]" color="black" opacity="0.6" />
              ) : undefined
            }
            state="enabled"
            to="/search"
          />
          <BottomNavigation
            active={false}
            className="!h-[56px] !flex-1 !flex !grow"
            icon={
              !iconOnly ? (
                <Favoritefilled18
                  className="!relative !w-[24px] !h-[24px] !object-cover"
                  color="black"
                  opacity="0.38"
                />
              ) : undefined
            }
            label={iconOnly ? false : true}
            labelContent={!iconOnly ? "Label" : undefined}
            override={
              iconOnly ? (
                <Favoritefilled18 className="!relative !w-[24px] !h-[24px]" color="black" opacity="0.38" />
              ) : undefined
            }
            state="disabled"
            to="/menu"
          />
          <BottomNavigation
            active={false}
            className="!h-[56px] !flex-1 !flex !grow"
            icon={
              !iconOnly ? (
                <Favoritefilled18 className="!relative !w-[24px] !h-[24px] !object-cover" color="black" opacity="0.6" />
              ) : undefined
            }
            label={iconOnly ? false : true}
            labelContent={!iconOnly ? "Label" : undefined}
            override={
              iconOnly ? (
                <Favoritefilled18 className="!relative !w-[24px] !h-[24px]" color="black" opacity="0.6" />
              ) : undefined
            }
            state="enabled"
            to="/profile"
          />
        </>
      )}
    </div>
  );
};

IconOnlyFalseWrapper.propTypes = {
  iconOnly: PropTypes.bool,
  items: PropTypes.oneOf(["three", "four"]),
  bottomNavigationLabelContent: PropTypes.string,
  bottomNavigationLabelContent1: PropTypes.string,
  bottomNavigationLabelContent2: PropTypes.string,
};