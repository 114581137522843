/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconXWrapper = ({ className, iconX = "/img/icon-x.png" }) => {
  return (
    <img className={`icon-x-wrapper ${className}`} alt="Icon x" src={iconX} />
  );
};

IconXWrapper.propTypes = {
  iconX: PropTypes.string,
};
