/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { ArrowRedo2 } from "../../icons/ArrowRedo2";
import { StyleOutline } from "../../icons/StyleOutline";
import { StyleSharp } from "../../icons/StyleSharp";

export const ArrowRedo = ({ style }) => {
  return (
    <>
      {style === "outline" && <StyleOutline className="!absolute !w-[16px] !h-[16px] !top-0 !left-0" />}

      {style === "fill" && <ArrowRedo2 className="!absolute !w-[16px] !h-[16px] !top-0 !left-0" color="black" />}

      {style === "sharp" && <StyleSharp className="!absolute !w-[16px] !h-[16px] !top-0 !left-0" />}
    </>
  );
};
