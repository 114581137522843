import React from "react";
import { ArrowRedo } from "../../components/ArrowRedo";
import { MenuItem } from "../../components/MenuItem";
import { Logo1 } from "../../icons/Logo1";

export const Menu = () => {
  return (
    <div className="bg-white flex flex-row justify-center w-full">
      <div className="bg-white overflow-hidden w-[393px] h-[1225px] relative" data-palette-mode="light">
        <div className="absolute w-[393px] h-[1039px] top-[186px] left-0">
          <img
            className="absolute w-[393px] h-[605px] top-[434px] left-0 object-cover"
            alt="Blue brush"
            src="https://c.animaapp.com/CgBrELct/img/blue-brush-1.png"
          />
          <img
            className="absolute w-[393px] h-[561px] top-0 left-0 object-cover"
            alt="Brush brown"
            src="https://c.animaapp.com/CgBrELct/img/brush-brown-1.png"
          />
          <p className="absolute w-[263px] top-[780px] left-[71px] [font-family:'Tisa_Sans_Pro-Light',Helvetica] font-light text-black text-[36px] tracking-[0] leading-[42.0px]">
            Find the art work to put a smile in your life
          </p>
          <div className="absolute w-[334px] h-[203px] top-[499px] left-[29px] [font-family:'Ofelia_Text-Light',Helvetica] font-light text-palette-primary-main text-[50px] text-center tracking-[-0.50px] leading-[60.0px]">
            {" "}
            Creativity Meets Community
          </div>
          <MenuItem
            className="!absolute !left-[90px] !top-[9px]"
            dense={false}
            disGutters={false}
            smallScreen
            stateProp="enabled"
            value="Home"
            valueClassName="!text-palette-text-primary !tracking-[var(--typography-h2-letter-spacing)] !text-[length:var(--typography-h2-font-size)] ![font-style:var(--typography-h2-font-style)] !font-[number:var(--typography-h2-font-weight)] !font-typography-h2 !leading-[var(--typography-h2-line-height)]"
          />
          <MenuItem
            className="!h-[84px] !left-[89px] !absolute !top-[113px]"
            dense={false}
            disGutters={false}
            smallScreen={false}
            stateProp="enabled"
            value="Browse"
            valueClassName="!text-palette-text-primary !tracking-[var(--typography-h2-letter-spacing)] !text-[length:var(--typography-h2-font-size)] ![font-style:var(--typography-h2-font-style)] !font-[number:var(--typography-h2-font-weight)] !font-typography-h2 !leading-[var(--typography-h2-line-height)]"
          />
          <MenuItem
            className="!left-[90px] !absolute !top-[205px]"
            dense={false}
            disGutters={false}
            smallScreen={false}
            stateProp="enabled"
            value="Artists"
            valueClassName="!text-palette-text-primary !tracking-[var(--typography-h2-letter-spacing)] !text-[length:var(--typography-h2-font-size)] ![font-style:var(--typography-h2-font-style)] !font-[number:var(--typography-h2-font-weight)] !font-typography-h2 !leading-[var(--typography-h2-line-height)]"
          />
          <MenuItem
            className="!left-[91px] !absolute !top-[297px]"
            dense={false}
            disGutters={false}
            smallScreen={false}
            stateProp="enabled"
            value="Events"
            valueClassName="!text-palette-text-primary !tracking-[var(--typography-h2-letter-spacing)] !text-[length:var(--typography-h2-font-size)] ![font-style:var(--typography-h2-font-style)] !font-[number:var(--typography-h2-font-weight)] !font-typography-h2 !leading-[var(--typography-h2-line-height)]"
          />
        </div>
        <div className="absolute w-[350px] h-[57px] top-[3153px] left-[18px]">
          <div className="relative h-[56px]">
            <div className="absolute w-[294px] h-[39px] top-0 left-[35px] bg-[#d3c1a5] rounded-[25px] border border-solid border-black" />
            <div className="absolute w-[31px] h-[29px] top-[4px] left-[277px]">
              <ArrowRedo style="fill" />
            </div>
            <div className="absolute w-[350px] top-[7px] left-0 [font-family:'Rounded_Mplus_1c_Bold-Bold',Helvetica] font-bold text-[#574a3e] text-[16px] text-center tracking-[0] leading-[normal]">
              Join Our Community
            </div>
          </div>
        </div>
        <Logo1 className="!absolute !w-[66px] !h-[80px] !top-[70px] !left-[158px]" />
      </div>
    </div>
  );
};
