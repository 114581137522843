/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconLinkedinWrapper = ({
  className,
  iconLinkedin = "/img/icon-linkedin.png",
}) => {
  return (
    <img
      className={`icon-linkedin-wrapper ${className}`}
      alt="Icon linkedin"
      src={iconLinkedin}
    />
  );
};

IconLinkedinWrapper.propTypes = {
  iconLinkedin: PropTypes.string,
};
