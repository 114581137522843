/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconJr } from "../IconJr";
import "./style.css";

export const Button = ({
  style,
  small,
  alternate,
  iconPosition,
  className,
  text = "Button",
  override = (
    <IconJr className="icon-JR-instance" iconJr="/img/icon-jr-18.png" />
  ),
  divClassName,
}) => {
  return (
    <div
      className={`button ${iconPosition} ${style} alternate-${alternate} small-${small} ${className}`}
    >
      {iconPosition === "no-icon" && (
        <div className={`div ${divClassName}`}>{text}</div>
      )}

      {["leading", "only"].includes(iconPosition) && (
        <IconJr
          className="icon-JR-instance"
          iconJr={
            iconPosition === "leading" && style === "link"
              ? "/img/icon-jr-18.png"
              : iconPosition === "only"
                ? "/img/icon-jr-2.png"
                : "/img/icon-jr.png"
          }
        />
      )}

      {["leading", "trailing"].includes(iconPosition) && (
        <div className="text-wrapper-2">{text}</div>
      )}

      {iconPosition === "trailing" && <>{override}</>}
    </div>
  );
};

Button.propTypes = {
  style: PropTypes.oneOf(["primary", "link", "secondary", "tertiary"]),
  small: PropTypes.bool,
  alternate: PropTypes.bool,
  iconPosition: PropTypes.oneOf(["no-icon", "trailing", "only", "leading"]),
  text: PropTypes.string,
};
