import React from "react";
import { AppBar } from "../../components/AppBar";
import { BottomNavigationWrapper } from "../../components/BottomNavigationWrapper";
import { ButtonSubscribe } from "../../components/ButtonSubscribe";
import { CustomFormsEmail } from "../../components/CustomFormsEmail";
import { LabelPlacementEndWrapper } from "../../components/LabelPlacementEndWrapper";
import { SizeLargeColorWrapper } from "../../components/SizeLargeColorWrapper";
import { Homefilled2 } from "../../icons/Homefilled2";
import { Logo1 } from "../../icons/Logo1";
import { Menufilled8 } from "../../icons/Menufilled8";
import { Peoplefilled } from "../../icons/Peoplefilled";
import { Searchfilled } from "../../icons/Searchfilled";

export const Search = () => {
  return (
    <div className="bg-white flex flex-row justify-center w-full">
      <div className="bg-white w-[393px] h-[3918px] relative" data-palette-mode="light">
        <img
          className="absolute w-[390px] h-[464px] top-[3448px] left-0 object-cover"
          alt="Blue brush"
          src="/img/blue-brush-1.png"
        />
        <ButtonSubscribe
          arrowRedoArrowRedoClassName="!h-[29px] !absolute bg-[url(/static/img/arrow-redo-4.svg)] !left-[277px] !w-[31px] !top-[4px]"
          className="!h-[57px] !absolute !left-[11px] !w-[350px] !top-[3340px]"
          divClassName="!text-[16px] !w-[350px] !top-[7px]"
          overlapGroupClassName="!h-[56px]"
          property1="default"
          rectangleClassName="!h-[39px] !left-[35px] !w-[294px]"
        />
        <CustomFormsEmail
          className="!h-[260px] !absolute !left-[17px] !w-[352px] !top-[3022px]"
          formcontrollabelFormLabelDivClassName="!tracking-[0.17px] !text-[14px] ![font-style:unset] !font-normal ![font-family:'Roboto',Helvetica] !leading-[20.0px]"
        />
        <div className="absolute w-[364px] h-[97px] top-[2889px] left-[27px] font-typography-h4 font-[number:var(--typography-h4-font-weight)] text-palette-primary-light text-[length:var(--typography-h4-font-size)] tracking-[var(--typography-h4-letter-spacing)] leading-[var(--typography-h4-line-height)] [font-style:var(--typography-h4-font-style)]">
          Where art meets heart
        </div>
        <img
          className="w-[391px] h-[214px] top-[2088px] absolute left-0 object-cover"
          alt="Snoqualmie"
          src="/img/snoqualmie-1.png"
        />
        <AppBar
          className="!absolute !left-0 !w-[393px] !top-0"
          color="primary"
          toolbarHasMinHeight={false}
          toolbarIconButtonIconIcon={<Menufilled8 className="!relative !w-[35px] !h-[35px]" color="#FDAC9C" />}
          toolbarIconButtonIconSize="large"
          toolbarIconButtonSize="large"
          toolbarMinHeightClassName="!mr-[-1.00px]"
          toolbarTypographyBodyClassName="!text-palette-primary-contrast !text-center"
          toolbarTypographyContent="Arts Link"
          toolbarTypographyVariant="h-4"
          toolbarTypographyVariantGutterClassName="!flex-[0_0_auto] !px-[30px] !py-0"
        />
        <div className="absolute w-[393px] h-[588px] top-[275px] left-0">
          <div className="flex flex-col w-[392px] items-center gap-[10px] p-[10px] absolute top-[514px] left-0">
            <BottomNavigationWrapper
              className="!mr-[-23.50px] !ml-[-23.50px] !flex-[0_0_auto] !flex !bg-white !w-[419px]"
              iconOnlyFalseWrapperBottomNavigationIcon={
                <Peoplefilled className="!relative !w-[24px] !h-[24px] !object-cover" color="#173F56" />
              }
              iconOnlyFalseWrapperBottomNavigationIcon1={
                <Searchfilled className="!relative !w-[24px] !h-[24px] !object-cover" color="#173F56" />
              }
              iconOnlyFalseWrapperIconOnlyFalseClassName="!h-[54px] !mr-[-10.00px] !shadow-elevation-1 !w-[429px]"
              override={<Homefilled2 className="!relative !w-[24px] !h-[24px] !object-cover" />}
              to="/search"
            />
          </div>
          <img
            className="absolute w-[393px] h-[455px] top-[82px] left-0 object-cover"
            alt="Brush brown"
            src="/img/brush-brown-3.png"
          />
          <div className="w-[393px] h-[378px] left-0 absolute top-0" />
        </div>
        <div className="absolute w-[306px] top-[895px] left-[38px] font-typography-h4 font-[number:var(--typography-h4-font-weight)] text-palette-primary-light text-[length:var(--typography-h4-font-size)] tracking-[var(--typography-h4-letter-spacing)] leading-[var(--typography-h4-line-height)] [font-style:var(--typography-h4-font-style)]">
          Artist: Juan Rubio
        </div>
        <div className="absolute w-[306px] top-[2014px] left-[30px] font-typography-h4 font-[number:var(--typography-h4-font-weight)] text-palette-primary-light text-[length:var(--typography-h4-font-size)] tracking-[var(--typography-h4-letter-spacing)] leading-[var(--typography-h4-line-height)] [font-style:var(--typography-h4-font-style)]">
          Artist: JK
        </div>
        <Logo1 className="!absolute !w-[66px] !h-[80px] !top-[92px] !left-[159px]" />
        <div
          className="!absolute !top-[225px] !border-2 !border-dashed !border-white">
        <video src="/img/Just Your Type.mov" autoPlay loop  /></div>
        <LabelPlacementEndWrapper
          className="!flex !absolute !left-[282px] !w-[92px] !top-[172px]"
          colorErrorStateWrapperDivClassName="!tracking-[var(--typography-body2-letter-spacing)] !text-[length:var(--typography-body2-font-size)] ![font-style:var(--typography-body2-font-style)] !font-[number:var(--typography-body2-font-weight)] !font-typography-body2 !leading-[var(--typography-body2-line-height)]"
          colorErrorStateWrapperValue="prints"
          disabled={false}
          labelPlacement="end"
        />
        <LabelPlacementEndWrapper
          className="!flex !left-[154px] !absolute !w-[104px] !top-[172px]"
          colorErrorStateWrapperDivClassName="!tracking-[var(--typography-body2-letter-spacing)] !text-[length:var(--typography-body2-font-size)] ![font-style:var(--typography-body2-font-style)] !font-[number:var(--typography-body2-font-weight)] !font-typography-body2 !leading-[var(--typography-body2-line-height)]"
          colorErrorStateWrapperValue="oil paint"
          disabled={false}
          labelPlacement="end"
        />
        <LabelPlacementEndWrapper
          className="!flex !left-[12px] !absolute !w-[131px] !top-[173px]"
          colorErrorStateWrapperDivClassName="!tracking-[var(--typography-body2-letter-spacing)] !text-[length:var(--typography-body2-font-size)] ![font-style:var(--typography-body2-font-style)] !font-[number:var(--typography-body2-font-weight)] !font-typography-body2 !leading-[var(--typography-body2-line-height)]"
          colorErrorStateWrapperValue="photography"
          disabled={false}
          labelPlacement="end"
        />
        <SizeLargeColorWrapper
          buttonClassName="!text-palette-primary-contrast !tracking-[var(--tooltip-label-letter-spacing)] !text-[length:var(--tooltip-label-font-size)] ![font-style:var(--tooltip-label-font-style)] !font-[number:var(--tooltip-label-font-weight)] !font-tooltip-label !leading-[var(--tooltip-label-line-height)]"
          className="!h-[22px] !flex !absolute !left-[27px] !bg-palette-primary-main !w-[107px] !top-[229px]"
          color="primary"
          label="apply filters"
          size="small"
          stateProp="enabled"
          variant="outlined"
        />
        <p className="absolute w-[313px] top-[1576px] left-[38px] [font-family:'Roboto',Helvetica] font-normal text-black text-[14px] tracking-[0.17px] leading-[20.0px]">
          Juan Rubio iS is a versatile artist whose creative journey spans multiple mediums, including photography,
          printmaking, and painting. His work draws inspiration from organic forms, capturing the intricate beauty of
          both the natural world and the people who inhabit it. S&#39;s artistic vision brings a harmonious blend of
          nature and humanity to life, creating a unique dialogue between the two.
          <br />
          <br />
          While oil painting has been the cornerstone of his artistic practice, S has recently embarked on new
          explorations with paper, watercolors, and printmaking. These mediums allow him to experiment with texture,
          color, and form in fresh and dynamic ways, pushing the boundaries of his creative expression. Through his
          diverse body of work, S invites viewers to appreciate the subtle connections and vibrant interplay between
          nature and human experience.
        </p>
        <img
          className="absolute w-[393px] h-[561px] top-[980px] left-0 object-cover"
          alt="Pluribus homo"
          src="/img/pluribus-homo-1.png"
        />
        <div className="absolute w-[393px] h-[499px] top-[2357px] left-0">
          <p className="absolute w-[313px] top-0 left-[42px] [font-family:'Roboto',Helvetica] font-normal text-black text-[14px] tracking-[0.17px] leading-[20.0px]">
            JK is a visionary photographer whose work captures the breathtaking beauty of nature across the globe. From
            the majestic flight of an eagle in the Adirondack Mountains to the elusive hyenas in the Rwandan wilderness,
            her images invite viewers to experience the world from fresh and inspiring perspectives. Through her lens,
            JK encourages us to pause, observe, and appreciate the vibrant and contemplative essence of the natural
            world.
          </p>
          <img
            className="w-[393px] h-[252px] top-[247px] absolute left-0 object-cover"
            alt="Snoqualmie"
            src="/img/snoqualmie-2.png"
          />
        </div>
      </div>
    </div>
  );
};
