/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Typography = ({ content = "Typography", variant, gutterBottom, className, bodyClassName }) => {
  return (
    <div
      className={`inline-flex flex-col items-start relative ${
        gutterBottom && ["body-1", "subtitle-1"].includes(variant)
          ? "pt-0 pb-[6px] px-0"
          : gutterBottom && ["body-2", "subtitle-2"].includes(variant)
          ? "pt-0 pb-[5px] px-0"
          : gutterBottom && ["caption", "overline"].includes(variant)
          ? "pt-0 pb-[4px] px-0"
          : variant === "h-6" && gutterBottom
          ? "pt-0 pb-[7px] px-0"
          : variant === "h-5" && gutterBottom
          ? "pt-0 pb-[8px] px-0"
          : variant === "h-4" && gutterBottom
          ? "pt-0 pb-[12px] px-0"
          : gutterBottom && variant === "h-3"
          ? "pt-0 pb-[17px] px-0"
          : variant === "h-2" && gutterBottom
          ? "pt-0 pb-[21px] px-0"
          : gutterBottom && variant === "h-1"
          ? "pt-0 pb-[34px] px-0"
          : ""
      } ${className}`}
    >
      <div
        className={`w-fit mt-[-1.00px] text-palette-text-primary relative whitespace-nowrap ${
          variant === "body-2"
            ? "font-typography-body2"
            : variant === "subtitle-1"
            ? "font-typography-subtitle1"
            : variant === "subtitle-2"
            ? "font-typography-subtitle2"
            : variant === "overline"
            ? "font-typography-overline"
            : variant === "caption"
            ? "font-typography-caption"
            : variant === "h-6"
            ? "font-typography-h6"
            : variant === "h-5"
            ? "font-typography-h5"
            : variant === "h-4"
            ? "font-typography-h4"
            : variant === "h-3"
            ? "font-typography-h3"
            : variant === "h-2"
            ? "font-typography-h2"
            : variant === "h-1"
            ? "font-typography-h1"
            : variant === "custom"
            ? "font-table-header"
            : "font-typography-body1"
        } ${
          variant === "body-2"
            ? "tracking-[var(--typography-body2-letter-spacing)]"
            : variant === "subtitle-1"
            ? "tracking-[var(--typography-subtitle1-letter-spacing)]"
            : variant === "subtitle-2"
            ? "tracking-[var(--typography-subtitle2-letter-spacing)]"
            : variant === "overline"
            ? "tracking-[var(--typography-overline-letter-spacing)]"
            : variant === "caption"
            ? "tracking-[var(--typography-caption-letter-spacing)]"
            : variant === "h-6"
            ? "tracking-[var(--typography-h6-letter-spacing)]"
            : variant === "h-5"
            ? "tracking-[var(--typography-h5-letter-spacing)]"
            : variant === "h-4"
            ? "tracking-[var(--typography-h4-letter-spacing)]"
            : variant === "h-3"
            ? "tracking-[var(--typography-h3-letter-spacing)]"
            : variant === "h-2"
            ? "tracking-[var(--typography-h2-letter-spacing)]"
            : variant === "h-1"
            ? "tracking-[var(--typography-h1-letter-spacing)]"
            : variant === "custom"
            ? "tracking-[var(--table-header-letter-spacing)]"
            : "tracking-[var(--typography-body1-letter-spacing)]"
        } ${
          variant === "body-2"
            ? "text-[length:var(--typography-body2-font-size)]"
            : variant === "subtitle-1"
            ? "text-[length:var(--typography-subtitle1-font-size)]"
            : variant === "subtitle-2"
            ? "text-[length:var(--typography-subtitle2-font-size)]"
            : variant === "overline"
            ? "text-[length:var(--typography-overline-font-size)]"
            : variant === "caption"
            ? "text-[length:var(--typography-caption-font-size)]"
            : variant === "h-6"
            ? "text-[length:var(--typography-h6-font-size)]"
            : variant === "h-5"
            ? "text-[length:var(--typography-h5-font-size)]"
            : variant === "h-4"
            ? "text-[length:var(--typography-h4-font-size)]"
            : variant === "h-3"
            ? "text-[length:var(--typography-h3-font-size)]"
            : variant === "h-2"
            ? "text-[length:var(--typography-h2-font-size)]"
            : variant === "h-1"
            ? "text-[length:var(--typography-h1-font-size)]"
            : variant === "custom"
            ? "text-[length:var(--table-header-font-size)]"
            : "text-[length:var(--typography-body1-font-size)]"
        } ${
          variant === "body-2"
            ? "[font-style:var(--typography-body2-font-style)]"
            : variant === "subtitle-1"
            ? "[font-style:var(--typography-subtitle1-font-style)]"
            : variant === "subtitle-2"
            ? "[font-style:var(--typography-subtitle2-font-style)]"
            : variant === "overline"
            ? "[font-style:var(--typography-overline-font-style)]"
            : variant === "caption"
            ? "[font-style:var(--typography-caption-font-style)]"
            : variant === "h-6"
            ? "[font-style:var(--typography-h6-font-style)]"
            : variant === "h-5"
            ? "[font-style:var(--typography-h5-font-style)]"
            : variant === "h-4"
            ? "[font-style:var(--typography-h4-font-style)]"
            : variant === "h-3"
            ? "[font-style:var(--typography-h3-font-style)]"
            : variant === "h-2"
            ? "[font-style:var(--typography-h2-font-style)]"
            : variant === "h-1"
            ? "[font-style:var(--typography-h1-font-style)]"
            : variant === "custom"
            ? "[font-style:var(--table-header-font-style)]"
            : "[font-style:var(--typography-body1-font-style)]"
        } ${
          variant === "body-2"
            ? "font-[number:var(--typography-body2-font-weight)]"
            : variant === "subtitle-1"
            ? "font-[number:var(--typography-subtitle1-font-weight)]"
            : variant === "subtitle-2"
            ? "font-[number:var(--typography-subtitle2-font-weight)]"
            : variant === "overline"
            ? "font-[number:var(--typography-overline-font-weight)]"
            : variant === "caption"
            ? "font-[number:var(--typography-caption-font-weight)]"
            : variant === "h-6"
            ? "font-[number:var(--typography-h6-font-weight)]"
            : variant === "h-5"
            ? "font-[number:var(--typography-h5-font-weight)]"
            : variant === "h-4"
            ? "font-[number:var(--typography-h4-font-weight)]"
            : variant === "h-3"
            ? "font-[number:var(--typography-h3-font-weight)]"
            : variant === "h-2"
            ? "font-[number:var(--typography-h2-font-weight)]"
            : variant === "h-1"
            ? "font-[number:var(--typography-h1-font-weight)]"
            : variant === "custom"
            ? "font-[number:var(--table-header-font-weight)]"
            : "font-[number:var(--typography-body1-font-weight)]"
        } ${
          variant === "body-2"
            ? "leading-[var(--typography-body2-line-height)]"
            : variant === "subtitle-1"
            ? "leading-[var(--typography-subtitle1-line-height)]"
            : variant === "subtitle-2"
            ? "leading-[var(--typography-subtitle2-line-height)]"
            : variant === "overline"
            ? "leading-[var(--typography-overline-line-height)]"
            : variant === "caption"
            ? "leading-[var(--typography-caption-line-height)]"
            : variant === "h-6"
            ? "leading-[var(--typography-h6-line-height)]"
            : variant === "h-5"
            ? "leading-[var(--typography-h5-line-height)]"
            : variant === "h-4"
            ? "leading-[var(--typography-h4-line-height)]"
            : variant === "h-3"
            ? "leading-[var(--typography-h3-line-height)]"
            : variant === "h-2"
            ? "leading-[var(--typography-h2-line-height)]"
            : variant === "h-1"
            ? "leading-[var(--typography-h1-line-height)]"
            : variant === "custom"
            ? "leading-[var(--table-header-line-height)]"
            : "leading-[var(--typography-body1-line-height)]"
        } ${bodyClassName}`}
      >
        {content}
      </div>
    </div>
  );
};

Typography.propTypes = {
  content: PropTypes.string,
  variant: PropTypes.oneOf([
    "h-5",
    "caption",
    "subtitle-1",
    "custom",
    "overline",
    "h-1",
    "h-6",
    "h-2",
    "subtitle-2",
    "body-1",
    "h-4",
    "h-3",
    "body-2",
  ]),
  gutterBottom: PropTypes.bool,
};
