/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { useReducer } from "react";

export const MenuItem = ({
  value = "Menu Item",
  divider = false,
  rightSlot = false,
  leftSlot = false,
  smallScreen,
  dense,
  disGutters,
  stateProp,
  className,
  valueClassName,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    smallScreen: smallScreen || false,
    dense: dense || false,
    disGutters: disGutters || false,
    state: stateProp || "enabled",
  });

  return (
    <div
      className={`inline-flex flex-col items-start justify-center relative ${
        state.state === "hovered"
          ? "bg-palette-action-hover-duplicate"
          : state.state === "selected"
          ? "bg-palette-primary-states-hover"
          : ""
      } ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div
        className={`inline-flex items-center flex-[0_0_auto] relative ${
          !state.dense && !state.smallScreen && state.disGutters
            ? "px-0 py-[6px]"
            : state.disGutters && state.smallScreen
            ? "px-0 py-[12px]"
            : state.dense && state.disGutters
            ? "px-0 py-[4px]"
            : !state.dense && !state.disGutters && !state.smallScreen
            ? "px-[16px] py-[6px]"
            : !state.disGutters && state.smallScreen
            ? "px-[16px] py-[12px]"
            : state.dense && !state.disGutters
            ? "px-[16px] py-[4px]"
            : ""
        }`}
      >
        <div className="inline-flex items-center flex-[0_0_auto] relative">
          <div
            className={`w-fit mt-[-1.00px] whitespace-nowrap relative ${
              state.dense ? "font-menu-itemdense" : "font-menu-itemdefault"
            } ${
              state.dense
                ? "tracking-[var(--menu-itemdense-letter-spacing)]"
                : "tracking-[var(--menu-itemdefault-letter-spacing)]"
            } ${
              state.dense
                ? "text-[length:var(--menu-itemdense-font-size)]"
                : "text-[length:var(--menu-itemdefault-font-size)]"
            } ${
              state.dense
                ? "[font-style:var(--menu-itemdense-font-style)]"
                : "[font-style:var(--menu-itemdefault-font-style)]"
            } ${
              state.state === "disabled"
                ? "text-palette-text-disabled-duplicate"
                : "text-palette-text-primary-duplicate"
            } ${
              state.dense
                ? "font-[number:var(--menu-itemdense-font-weight)]"
                : "font-[number:var(--menu-itemdefault-font-weight)]"
            } ${
              state.dense
                ? "leading-[var(--menu-itemdense-line-height)]"
                : "leading-[var(--menu-itemdefault-line-height)]"
            } ${valueClassName}`}
          >
            {value}
          </div>
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hovered",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "enabled",
      };
  }

  return state;
}
