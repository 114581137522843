/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Starsharp102 } from "../../icons/Starsharp102";

export const Icon = ({
  size,
  className,
  icon = <Starsharp102 className="!relative !w-[35px] !h-[35px]" color="black" opacity="0.56" />,
}) => {
  return (
    <div
      className={`items-start relative ${size === "inherit" ? "w-[16px]" : ""} ${
        size === "inherit" ? "flex" : "inline-flex"
      } ${size === "inherit" ? "h-[16px]" : ""} ${className}`}
    >
      {icon}
    </div>
  );
};

Icon.propTypes = {
  size: PropTypes.oneOf(["large", "inherit", "medium", "small"]),
};
