/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const BannerAnimation = ({ className, divClassName }) => {
  return (
    <div
      className={`inline-flex h-[46px] items-center justify-center gap-[10px] px-[28px] py-[27px] relative bg-white ${className}`}
    >
      <p
        className={`relative w-fit mt-[-21.00px] mb-[-19.00px] font-typography-h6 font-[number:var(--typography-h6-font-weight)] text-palette-primary-dark text-[length:var(--typography-h6-font-size)] text-center tracking-[var(--typography-h6-letter-spacing)] leading-[var(--typography-h6-line-height)] whitespace-nowrap [font-style:var(--typography-h6-font-style)] ${divClassName}`}
      >
        we curate - we connect - we promote
      </p>
    </div>
  );
};
