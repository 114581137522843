/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Hidden201 = ({ opacity = "0.38", className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="28"
      viewBox="0 0 29 28"
      width="29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6667 5.83333V22.1667H6.33333V5.83333H22.6667ZM22.6667 3.5H6.33333C5.05 3.5 4 4.55 4 5.83333V22.1667C4 23.45 5.05 24.5 6.33333 24.5H22.6667C23.95 24.5 25 23.45 25 22.1667V5.83333C25 4.55 23.95 3.5 22.6667 3.5Z"
        fill="black"
        fillOpacity={opacity}
      />
    </svg>
  );
};

Hidden201.propTypes = {
  opacity: PropTypes.string,
};
