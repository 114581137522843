/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Favoritefilled18 } from "../../icons/Favoritefilled18";
import { Favoritefilled8 } from "../../icons/Favoritefilled8";
import { Link } from "react-router-dom";

export const BottomNavigation = ({
  labelContent = "Label",
  active,
  label,
  state,
  className,
  icon = <Favoritefilled18 className="!relative !w-[24px] !h-[24px] !object-cover" color="black" opacity="0.6" />,
  override = <Favoritefilled8 className="!relative !w-[24px] !h-[24px]" color="#173F56" />,
  to,
}) => {
  return (
    <Link to={to} className={`inline-flex relative ${label ? "flex-col" : ""} ${label ? "items-center" : "items-start"} ${
      !label ? "gap-[10px]" : ""
    } ${label ? "pt-[6px] pb-[8px] px-[12px]" : "pt-[16px] pb-[8px] px-[12px]"} ${!label ? "justify-center" : ""} ${
      active && state === "focused" ? "bg-palette-primary-focusvisible" : !active && state === "focused" ? "bg-palette-text-focusvisible" : ""
    } ${className}`}
    >
      {!label && <>{override}</>}
      {label && (
        <>
          {icon}
          <div className="inline-flex items-start h-[16px] relative">
            <div
              className={`w-fit mt-[-1.00px] relative text-center whitespace-nowrap ${
                !active ? "font-typography-caption" : "font-bottomnavigation-activelabel"
              } ${!active ? "tracking-[var(--typography-caption-letter-spacing)]" : "tracking-[var(--bottomnavigation-activelabel-letter-spacing)]"} ${
                !active ? "text-[length:var(--typography-caption-font-size)]" : "text-[length:var(--bottomnavigation-activelabel-font-size)]"
              } ${!active ? "[font-style:var(--typography-caption-font-style)]" : "[font-style:var(--bottomnavigation-activelabel-font-style)]"} ${
                active ? "text-palette-primary-main" : !active && ["enabled", "focused"].includes(state) ? "text-palette-text-secondary" : state === "disabled" ? "text-palette-text-disabled" : ""
              } ${!active ? "font-[number:var(--typography-caption-font-weight)]" : "font-[number:var(--bottomnavigation-activelabel-font-weight)]"} ${
                !active ? "mb-[-3.00px]" : "mb-[-6.00px]"
              } ${!active ? "leading-[var(--typography-caption-line-height)]" : "leading-[var(--bottomnavigation-activelabel-line-height)]"}`}
            >
              {labelContent}
            </div>
          </div>
        </>
      )}
    </Link>
  );
};

BottomNavigation.propTypes = {
  labelContent: PropTypes.string,
  active: PropTypes.bool,
  label: PropTypes.bool,
  state: PropTypes.oneOf(["disabled", "focused", "enabled"]),
  to: PropTypes.string.isRequired,
};
