/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconLinkedin1 = ({ className }) => {
  return (
    <svg
      className={`icon-linkedin-1 ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M4.5 3.74268C3.67157 3.74268 3 4.41425 3 5.24268V20.2427C3 21.0711 3.67157 21.7427 4.5 21.7427H19.5C20.3284 21.7427 21 21.0711 21 20.2427V5.24268C21 4.41425 20.3284 3.74268 19.5 3.74268H4.5ZM8.52076 7.7454C8.52639 8.70165 7.81061 9.29087 6.96123 9.28665C6.16107 9.28243 5.46357 8.6454 5.46779 7.74681C5.47201 6.90165 6.13998 6.22243 7.00764 6.24212C7.88795 6.26181 8.52639 6.90728 8.52076 7.7454ZM12.2797 10.5044H9.75971H9.7583V19.0643H12.4217V18.8646C12.4217 18.4847 12.4214 18.1047 12.4211 17.7246C12.4203 16.7108 12.4194 15.6959 12.4246 14.6824C12.426 14.4363 12.4372 14.1804 12.5005 13.9455C12.7381 13.068 13.5271 12.5013 14.4074 12.6406C14.9727 12.7291 15.3467 13.0568 15.5042 13.5898C15.6013 13.923 15.6449 14.2816 15.6491 14.629C15.6605 15.6766 15.6589 16.7242 15.6573 17.7719C15.6567 18.1417 15.6561 18.5117 15.6561 18.8815V19.0629H18.328V18.8576C18.328 18.4056 18.3278 17.9537 18.3275 17.5018C18.327 16.3723 18.3264 15.2428 18.3294 14.1129C18.3308 13.6024 18.276 13.099 18.1508 12.6054C17.9638 11.8713 17.5771 11.2638 16.9485 10.8251C16.5027 10.5129 16.0133 10.3118 15.4663 10.2893C15.404 10.2867 15.3412 10.2833 15.2781 10.2799C14.9984 10.2648 14.7141 10.2494 14.4467 10.3033C13.6817 10.4566 13.0096 10.8068 12.5019 11.4241C12.4429 11.4949 12.3852 11.5668 12.2991 11.6741L12.2797 11.6984V10.5044ZM5.68164 19.0671H8.33242V10.51H5.68164V19.0671Z"
        fill="#5C191C"
        fillRule="evenodd"
      />
    </svg>
  );
};
