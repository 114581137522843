/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";

export const TextField = ({
  placeholder = "Placeholder",
  value = "Value",
  adornEnd = false,
  placeholder1 = false,
  value1 = true,
  label = "Label",
  adornStart = false,
  helper = false,
  variant,
  size,
  stateProp,
  hasValue,
  className,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    variant: variant || "standard",
    size: size || "medium",
    state: stateProp || "enabled",
    hasValue: hasValue || true,
  });

  return (
    <div
      className={`w-[220px] flex flex-col items-start relative ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      {((!state.hasValue && state.size === "medium") ||
        (!state.hasValue && state.size === "small" && state.state === "disabled" && state.variant === "filled") ||
        (!state.hasValue && state.size === "small" && state.state === "disabled" && state.variant === "standard") ||
        (!state.hasValue && state.size === "small" && state.state === "hovered" && state.variant === "filled") ||
        (!state.hasValue && state.size === "small" && state.state === "hovered" && state.variant === "standard") ||
        (!state.hasValue && state.size === "small" && state.variant === "outlined") ||
        (state.hasValue && state.size === "medium" && state.state === "disabled" && state.variant === "outlined") ||
        (state.hasValue && state.size === "medium" && state.state === "hovered" && state.variant === "outlined") ||
        (state.hasValue && state.size === "medium" && state.variant === "filled") ||
        (state.hasValue && state.size === "medium" && state.variant === "standard") ||
        (state.hasValue && state.size === "small" && state.state === "disabled") ||
        (state.hasValue && state.size === "small" && state.state === "enabled" && state.variant === "outlined") ||
        (state.hasValue && state.size === "small" && state.state === "hovered") ||
        (state.hasValue && state.state === "error" && state.variant === "outlined") ||
        (state.hasValue && state.state === "focused" && state.variant === "outlined") ||
        (state.size === "small" && state.state === "enabled" && state.variant === "filled") ||
        (state.size === "small" && state.state === "enabled" && state.variant === "standard") ||
        (state.size === "small" && state.state === "error" && state.variant === "filled") ||
        (state.size === "small" && state.state === "error" && state.variant === "standard") ||
        (state.size === "small" && state.state === "focused" && state.variant === "filled") ||
        (state.size === "small" && state.state === "focused" && state.variant === "standard")) && (
        <div
          className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] relative ${
            (state.state === "disabled" && state.variant === "outlined") ||
            (state.state === "enabled" && state.variant === "outlined") ||
            (state.state === "error" && state.variant === "outlined") ||
            (state.state === "hovered" && state.variant === "outlined")
              ? "border border-solid"
              : state.variant === "outlined" && state.state === "focused"
              ? "border-2 border-solid"
              : ""
          } ${
            state.variant === "outlined" && ["disabled", "enabled"].includes(state.state)
              ? "border-palette-components-input-outlined-enabledborder"
              : state.variant === "outlined" && state.state === "error"
              ? "border-palette-error-main"
              : state.variant === "outlined" && state.state === "focused"
              ? "border-palette-primary-main"
              : state.state === "hovered" && state.variant === "outlined"
              ? "border-palette-components-input-outlined-hoverborder"
              : ""
          } ${
            state.variant === "standard" && state.size === "medium"
              ? "gap-[6px]"
              : state.size === "small" && state.variant === "standard"
              ? "gap-[4.5px]"
              : ""
          } ${state.variant === "outlined" ? "px-[12px] py-0" : ""} ${
            state.variant === "filled" ? "overflow-hidden" : ""
          } ${
            state.variant === "filled"
              ? "rounded-[var(--shape-borderradius)_var(--shape-borderradius)_var(--shape-none)_var(--shape-none)]"
              : state.variant === "outlined"
              ? "rounded-[var(--shape-borderradius)]"
              : ""
          } ${
            (state.state === "disabled" && state.variant === "filled") ||
            (state.state === "enabled" && state.variant === "filled") ||
            (state.state === "error" && state.variant === "filled") ||
            (state.state === "focused" && state.variant === "filled")
              ? "bg-palette-components-input-filled-enabledfill"
              : state.state === "hovered" && state.variant === "filled"
              ? "bg-palette-components-input-filled-hoverfill"
              : ""
          }`}
        >
          {state.variant === "standard" && (
            <>
              <div
                className={`font-input-label w-fit mt-[-1.00px] tracking-[var(--input-label-letter-spacing)] text-[length:var(--input-label-font-size)] [font-style:var(--input-label-font-style)] font-[number:var(--input-label-font-weight)] leading-[var(--input-label-line-height)] whitespace-nowrap relative ${
                  !state.hasValue ? "opacity-0" : ""
                } ${
                  state.state === "error"
                    ? "text-palette-error-main"
                    : state.state === "disabled"
                    ? "text-palette-text-disabled"
                    : state.state === "focused"
                    ? "text-palette-primary-main"
                    : "text-palette-text-secondary"
                }`}
              >
                {!state.hasValue && <>Label</>}

                {state.hasValue && <>{label}</>}
              </div>
              <div
                className={`self-stretch relative ${!state.hasValue ? "font-input-value" : ""} ${
                  state.hasValue ? "min-h-[24px]" : ""
                } ${state.hasValue ? "w-full" : ""} ${state.hasValue ? "flex" : ""} ${
                  !state.hasValue ? "tracking-[var(--input-value-letter-spacing)]" : ""
                } ${!state.hasValue ? "[font-style:var(--input-value-font-style)]" : ""} ${
                  !state.hasValue ? "text-[length:var(--input-value-font-size)]" : ""
                } ${state.hasValue ? "min-w-[24px]" : ""} ${state.hasValue ? "items-center" : ""} ${
                  (!state.hasValue && state.state === "enabled") ||
                  (!state.hasValue && state.state === "error") ||
                  (!state.hasValue && state.state === "focused") ||
                  (!state.hasValue && state.state === "hovered")
                    ? "text-palette-text-secondary"
                    : state.state === "disabled" && !state.hasValue
                    ? "text-palette-text-disabled"
                    : ""
                } ${state.hasValue ? "flex-[0_0_auto]" : ""} ${
                  !state.hasValue ? "font-[number:var(--input-value-font-weight)]" : ""
                } ${!state.hasValue ? "leading-[var(--input-value-line-height)]" : ""}`}
              >
                {!state.hasValue && <>{label}</>}

                {state.hasValue && (
                  <>
                    <>
                      {value1 && (
                        <div
                          className={`font-input-value mt-[-1.00px] tracking-[var(--input-value-letter-spacing)] text-[length:var(--input-value-font-size)] [font-style:var(--input-value-font-style)] flex-1 font-[number:var(--input-value-font-weight)] leading-[var(--input-value-line-height)] relative ${
                            state.state === "disabled" ? "text-palette-text-disabled" : "text-palette-text-primary"
                          }`}
                        >
                          {value}
                        </div>
                      )}
                    </>
                  </>
                )}
              </div>
              <img
                className={`w-full self-stretch relative ${
                  ["disabled", "enabled"].includes(state.state) ? "object-cover" : ""
                } ${["disabled", "enabled"].includes(state.state) ? "h-px" : "h-[2px]"}`}
                alt="Underline"
                src={
                  state.state === "error" &&
                  (state.hasValue || state.size === "small") &&
                  (!state.hasValue || state.size === "medium")
                    ? "/img/underline-6.svg"
                    : state.state === "disabled" &&
                      (state.hasValue || state.size === "medium") &&
                      (!state.hasValue || state.size === "small")
                    ? "/img/underline-33.svg"
                    : state.state === "focused" &&
                      (state.hasValue || state.size === "medium") &&
                      (!state.hasValue || state.size === "small")
                    ? "/img/underline-35.svg"
                    : state.state === "focused" &&
                      (state.hasValue || state.size === "small") &&
                      (!state.hasValue || state.size === "medium")
                    ? "/img/underline-10.svg"
                    : state.state === "disabled" &&
                      (state.hasValue || state.size === "small") &&
                      (!state.hasValue || state.size === "medium")
                    ? "/img/underline-9.svg"
                    : state.state === "enabled" &&
                      (state.hasValue || state.size === "medium") &&
                      (!state.hasValue || state.size === "small")
                    ? "/img/underline-32.svg"
                    : state.state === "enabled" &&
                      (state.hasValue || state.size === "small") &&
                      (!state.hasValue || state.size === "medium")
                    ? "/img/underline-14.svg"
                    : state.state === "hovered" &&
                      (state.hasValue || state.size === "medium") &&
                      (!state.hasValue || state.size === "small")
                    ? "/img/underline-39.svg"
                    : state.state === "hovered" &&
                      (state.hasValue || state.size === "small") &&
                      (!state.hasValue || state.size === "medium")
                    ? "/img/underline-18.svg"
                    : "/img/underline-30.svg"
                }
              />
            </>
          )}

          {state.variant === "filled" && (
            <>
              <div
                className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] relative ${
                  state.size === "medium" ? "gap-[3px]" : "gap-[2px]"
                } ${
                  state.size === "small" && state.hasValue
                    ? "pt-[6px] pb-[4px] px-[12px]"
                    : !state.hasValue && state.size === "medium"
                    ? "px-[12px] py-[16px]"
                    : state.size === "medium" && state.hasValue
                    ? "pt-[9px] pb-[8px] px-[12px]"
                    : "p-[12px]"
                }`}
              >
                {!state.hasValue && (
                  <div className="font-input-value self-stretch mt-[-1.00px] tracking-[var(--input-value-letter-spacing)] text-[length:var(--input-value-font-size)] [font-style:var(--input-value-font-style)] text-palette-text-secondary font-[number:var(--input-value-font-weight)] leading-[var(--input-value-line-height)] relative">
                    {label}
                  </div>
                )}

                {state.hasValue && (
                  <>
                    <div
                      className={`font-input-label w-fit mt-[-1.00px] tracking-[var(--input-label-letter-spacing)] text-[length:var(--input-label-font-size)] [font-style:var(--input-label-font-style)] font-[number:var(--input-label-font-weight)] leading-[var(--input-label-line-height)] whitespace-nowrap relative ${
                        state.state === "focused"
                          ? "text-palette-primary-main"
                          : state.state === "disabled"
                          ? "text-palette-text-disabled"
                          : state.state === "error"
                          ? "text-palette-error-main"
                          : "text-palette-text-secondary"
                      }`}
                    >
                      {label}
                    </div>
                    <div className="w-full flex self-stretch items-center flex-[0_0_auto] relative">
                      {["disabled", "enabled", "error", "hovered"].includes(state.state) && (
                        <div className="w-px h-[24px] relative" />
                      )}

                      {value1 && (
                        <div
                          className={`font-input-value mt-[-1.00px] tracking-[var(--input-value-letter-spacing)] text-[length:var(--input-value-font-size)] [font-style:var(--input-value-font-style)] flex-1 font-[number:var(--input-value-font-weight)] leading-[var(--input-value-line-height)] relative ${
                            state.state === "disabled" ? "text-palette-text-disabled" : "text-palette-text-primary"
                          }`}
                        >
                          {value}
                        </div>
                      )}

                      <div
                        className={`relative ${state.state === "focused" ? "w-px" : "w-[24px]"} ${
                          state.state === "focused" ? "h-[24px]" : "h-px"
                        }`}
                      />
                      {state.state === "focused" && <div className="w-[24px] h-px relative" />}
                    </div>
                  </>
                )}
              </div>
              <img
                className={`w-[220px] left-0 absolute ${
                  state.size === "small" && ["disabled", "enabled"].includes(state.state)
                    ? "top-[47px]"
                    : state.size === "medium" && ["disabled", "enabled"].includes(state.state)
                    ? "top-[55px]"
                    : state.size === "medium" && ["error", "focused", "hovered"].includes(state.state)
                    ? "top-[54px]"
                    : "top-[46px]"
                } ${["disabled", "enabled"].includes(state.state) ? "object-cover" : ""} ${
                  ["disabled", "enabled"].includes(state.state) ? "h-px" : "h-[2px]"
                }`}
                alt="Underline"
                src={
                  state.state === "focused"
                    ? "/img/underline-35.svg"
                    : state.state === "disabled"
                    ? "/img/underline-33.svg"
                    : state.state === "error"
                    ? "/img/underline-30.svg"
                    : state.state === "hovered"
                    ? "/img/underline-39.svg"
                    : "/img/underline-32.svg"
                }
              />
            </>
          )}

          {state.variant === "outlined" && !state.hasValue && (
            <div
              className={`min-h-[24px] w-full flex self-stretch items-center flex-[0_0_auto] overflow-hidden relative ${
                state.size === "small" ? "px-0 py-[8px]" : "px-0 py-[16px]"
              }`}
            >
              <div
                className={`font-input-value mt-[-1.00px] tracking-[var(--input-value-letter-spacing)] text-[length:var(--input-value-font-size)] [font-style:var(--input-value-font-style)] flex-1 font-[number:var(--input-value-font-weight)] leading-[var(--input-value-line-height)] relative ${
                  state.state === "disabled"
                    ? "text-palette-text-disabled"
                    : state.size === "medium" && state.state === "focused"
                    ? "text-palette-text-primary"
                    : "text-palette-text-secondary"
                }`}
              >
                Label
              </div>
            </div>
          )}

          {state.variant === "outlined" && state.hasValue && (
            <>
              <div
                className={`min-h-[24px] w-full flex min-w-[24px] self-stretch items-center flex-[0_0_auto] relative ${
                  state.size === "small" ? "px-0 py-[8px]" : "px-0 py-[16px]"
                }`}
              >
                {value1 && (
                  <div
                    className={`font-input-value mt-[-1.00px] tracking-[var(--input-value-letter-spacing)] text-[length:var(--input-value-font-size)] [font-style:var(--input-value-font-style)] flex-1 relative font-[number:var(--input-value-font-weight)] leading-[var(--input-value-line-height)] ${
                      state.state === "disabled" ? "text-palette-text-disabled" : "text-palette-text-primary"
                    }`}
                  >
                    {value}
                  </div>
                )}
              </div>
              <div className="left-[12px] inline-flex items-center top-0 gap-[10px] px-[4px] py-0 h-[2px] bg-palette-background-paper-elevation-0 absolute">
                <div
                  className={`font-input-label w-fit mt-[-6.00px] tracking-[var(--input-label-letter-spacing)] text-[length:var(--input-label-font-size)] [font-style:var(--input-label-font-style)] relative font-[number:var(--input-label-font-weight)] whitespace-nowrap mb-[-4.00px] leading-[var(--input-label-line-height)] ${
                    state.state === "error"
                      ? "text-palette-error-main"
                      : state.state === "disabled"
                      ? "text-palette-text-disabled"
                      : state.state === "focused"
                      ? "text-palette-primary-main"
                      : "text-palette-text-secondary"
                  }`}
                >
                  {label}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {state.variant === "outlined" && state.hasValue && state.size === "medium" && state.state === "enabled" && (
        <>
          <div className="flex flex-col items-start px-[12px] py-0 relative self-stretch w-full flex-[0_0_auto] rounded-[var(--shape-borderradius)] border border-solid border-palette-components-input-outlined-enabledborder">
            <div className="flex min-w-[24px] min-h-[24px] items-center px-0 py-[16px] relative self-stretch w-full flex-[0_0_auto]">
              {value1 && (
                <div className="relative flex-1 mt-[-1.00px] font-input-value font-[number:var(--input-value-font-weight)] text-palette-text-primary text-[length:var(--input-value-font-size)] tracking-[var(--input-value-letter-spacing)] leading-[var(--input-value-line-height)] [font-style:var(--input-value-font-style)]">
                  {value}
                </div>
              )}
            </div>
          </div>
          <div className="inline-flex h-[2px] items-center gap-[10px] px-[4px] py-0 absolute top-0 left-[12px] bg-palette-background-paper-elevation-0">
            <div className="relative w-fit mt-[-6.00px] mb-[-4.00px] font-input-label font-[number:var(--input-label-font-weight)] text-palette-text-secondary text-[length:var(--input-label-font-size)] tracking-[var(--input-label-letter-spacing)] leading-[var(--input-label-line-height)] whitespace-nowrap [font-style:var(--input-label-font-style)]">
              {label}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hovered",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "enabled",
      };
  }

  return state;
}

TextField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  adornEnd: PropTypes.bool,
  placeholder1: PropTypes.bool,
  value1: PropTypes.bool,
  label: PropTypes.string,
  adornStart: PropTypes.bool,
  helper: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  size: PropTypes.oneOf(["medium", "small"]),
  stateProp: PropTypes.oneOf(["enabled", "focused", "hovered", "error", "disabled"]),
  hasValue: PropTypes.bool,
};
