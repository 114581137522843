/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconFacebook = ({
  className,
  iconFacebook = "/img/icon-facebook.png",
}) => {
  return (
    <img
      className={`icon-facebook ${className}`}
      alt="Icon facebook"
      src={iconFacebook}
    />
  );
};

IconFacebook.propTypes = {
  iconFacebook: PropTypes.string,
};
